import { makeStyles } from '@material-ui/core/styles';

export const MedicationPageStyle = makeStyles(() => ({
  root: {
    flexDirection: 'row',
    '& .buttons': {
      display: 'flex',
      justifyContent: 'space-around',
      '& .footerDiv': {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '10%',
        padding: '0px 15px',
        maxWidth: '375px',
        textAlign: 'center',
        margin: '0px auto',
        background: '#fff',
        '& span': {
          display: 'block',
        },
        '& .footerButton': {
          textDecoration: 'none',
          fontSize: '10px',
          color: '#898888',
          fontWeight: 400,
          lineHeight: '19px',
          display: 'none',
        },
      },
    },
  },
}));
