import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SurveyPageStyle } from './styles';
import helpIcon from '../../Resources/Assets/helpModal.svg';
import logoIcon from '../../ErrorPage/Assets/logo-icon.png';
import Help from '../../ErrorPage/Assets/help.svg';

export function ErrorComp() {
  const classes = SurveyPageStyle();
  const history = useHistory()
  const location = useLocation();
  const [activemodal, setActiveModal] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState('')
  const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSeMfDO3GZvZgXYyfltMjNWPgHjzUIIfm9HFyMvmaAGkp64DeA/viewform?pli=1';

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setErrMsg(params.get('msg') || "");
  }, [])

  function Modal() {
    setActiveModal(true)
  }

  return (
    <div className={classes.root}>
      <div className={classes.login}>
        <div className='topWrap'>
          {
            errMsg ?
              <>{errMsg}</>
              :
              <h2>Oops! Something seems wrong.
                <p>If this problem repeats, please contact <br />our help desk.</p>
              </h2>
          }
          <span className='icon'><img
            src={logoIcon}
            alt="welcome icon"
          ></img></span>
        </div>
        <div className='bottomWrap'>
          <div className='loginDetail'>
            <div className='loginBtn'>
              <button className='btn-login active' onClick={() => history.push('/taps')}>Go to your Tappt account</button>
            </div>
          </div>
        </div>
        <div className='help-wrap'>
          <button className='help-btn' onClick={Modal} ><img src={Help} />Help desk</button>
        </div>
      </div>
      {/* --- Modal  --- */}
      <div className={`${classes.modal} ${classes.helpModal} ${activemodal ? classes.modalActive : ""}`}>
        <div className='overlay'></div>
        <div className='modalContent'>
          <span className='icon'><img src={helpIcon} /></span>
          <h2>Do you need any technical assistance?</h2>
          <p className='desc'><span>Submit a request to our team.</span>
            Important! Please note that the Tappt team is unable to answer or provide any medical or medication related questions. Please contact your healthcare provider for these inquiries. We will be happy to help with any technical related questions.</p>
          <div className='close-btn'>
            <button onClick={() => { setActiveModal(false); window.open(formLink) }}>Got it</button>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ErrorComp;
