import React, { memo } from 'react';
import styled from 'styled-components/macro';
import Path from '../../containers/Taps/Assets/checkcricle.svg';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props extends InputProps {
  id: string;
  label: string;
  className?: string;
  isSelected?: boolean;
}

export const CheckBox = memo(
  ({ id, label, isSelected, ...restOf }: Props) => {
    return (
      <Wrapper className="check-grp">
        <input type="checkbox" id={id} checked={isSelected} readOnly {...restOf} />
        <label htmlFor={id}>{label}</label>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`

    &.check-grp{
      margin-right:5px;
      &:last-child{
        
      }
    }
    input[type='checkbox'] {
      margin: 0;
      opacity: 0;
      width: 0;
      height: 0;
      padding: 0;

      + label {
        margin: 0;
        display: inline-block;
        /* padding: 9px 0 9px 40px; */
        position: relative;
        cursor: pointer;
        font-size: 0.875rem;
        z-index: 1;
        min-height: 34px;
        min-width: 34px;
        padding-left: 34px;
        a {
          text-decoration: none;
        }

        &::before {
          position: absolute;
          top: 0.25rem;
          left: 0;
          display: inline-block;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: ${p => p.theme.background};
          content: '';
          border: 2px solid #E0DEEA;
          transition: all 0.1s;
          background-color: #F2F1F7;
        }

        &::after {
          display: none;
          content: '';
          position: absolute;
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: 11px;
          left: 7px;
          background-color: ${p => p.theme.background};
        }

        &:hover {
          &::before {
            border-color: ${p => p.theme.primary};
          }
        }
      }

      &:disabled {
        + label {
          opacity: 0.6;
          cursor: auto;

          &:hover {
            &::before {
              border-color: #E0DEEA;
            }
          }
        }
      }

      &:focus {
        + label {
          &::before {
              ${p =>
    p?.theme?.primary?.replace(
      /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
      'rgba$1,0.2)',
    )};
          }
        }
      }

      &:checked {
        + label {
          &::before {
            background-color: ${p => p.theme.primary};
            border: 2px solid #F26F62;
          }

          &::after {
            display: inline-block;
            background-image:url('.${Path}');
          }
        }
      }
    }
`;
