import { axiosInstance } from '.';

export const createPatientAnswer = async (
  qId: number,
  anwers: number[],
  assignId: number,
) => {
  const rsp = await axiosInstance.post('patientanswers', {
    questionId: qId,
    answersIds: anwers,
    assignId,
  });
  return rsp.data;
};
