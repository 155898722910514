import { axiosInstance } from '.';

export const getTapsHistory = async (start, end) => {
  const rsp = await axiosInstance.post(`patienttap/history`, { start, end });
  return rsp?.data ? rsp?.data : [];
};

export const createTap = async label_id => {
  const rsp = await axiosInstance.post('patienttap/', { label_id });
  return rsp.data;
};

export const POSTPatientnextTap = async () => {
  const rsp = await axiosInstance.post(`patienttap/next-tap`);
  return rsp?.data;
};
