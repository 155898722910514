import * as React from 'react';
import { Route } from 'react-router-dom';
import CheckInsPageHtml from './UserListView/htm';

export function CheckInsDataPage({ match }) {
  return (
    <>
      <Route exact path={match.url} component={CheckInsPageHtml} />
    </>
  );
}
