import axios, { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { axiosInstance } from 'services/api';

type Props = RouteProps & {};

const checkIsLoggedOut = (err: unknown) => {
  console.log('err: ', err);
  if (err instanceof AxiosError) return err.response?.status === 401;
  return false;
};
function PrivateRoute(props: Props) {
  const history = useHistory();

  useEffect(() => {
    const interceptorId = axiosInstance.interceptors.response.use(
      undefined,
      err =>
        checkIsLoggedOut(err) ? history.push('/landing') : console.log({ err }),
    );
    console.log('interceptorId: ', interceptorId);
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  });

  return <Route {...props} />;
}

export default PrivateRoute;
