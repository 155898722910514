import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSmStylesOtp } from './styles';
import logoIcon from '../../Assets/logo-icon.png';

export function ComponentPage() {
    const classesSm = useSmStylesOtp();
    const history = useHistory();

    return (
        <div className={classesSm.root}>
            <div className={classesSm.login}>
                <div className='topWrap'>
                    <h3>Password Successfully updated</h3>
                    <span className='icon'><img
                        src={logoIcon}
                        alt="welcome icon"
                    ></img></span>
                </div>
                <div className='bottomWrap'>
                    <div className='loginDetail'>
                        <div className='loginBtn'>
                            <button className='btn-login active' onClick={() => history.push('/login')}>Login</button>
                        </div>
                        <div className='copyRight'>
                            <p>&copy; Powered by <Link to="/login" target="_blank">Synchronyx</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComponentPage;
