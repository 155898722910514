import { IMedication } from './Medication';

interface IPrevLabel {
  patientTaps?: any[] | null
}

export interface LabeledMedication {
  id: number;
  medication: IMedication;
  newDate: Date;
  amount: number;
  quantity: number;
  time_period: number;
  maximum_units_per_day: number;
  Reminder_threshold: number;
  number_of_days_covered: number;
  quantity_per_container: number;
  number_of_containers: number;
  createdAt: Date;
  endsAt: Date;
  discontinuedAt?: Date;
  adjustedAt?: Date;
  patientTaps: any[];
  activatedAt: Date;
  firstTap: string;
  prevLabelMedication?: IPrevLabel
}

export interface GroupedLebelMed {
  Adjusted: LabeledMedication[];
  Discontinued: LabeledMedication[];
  Expired: LabeledMedication[];
  'Awaiting First Tap': LabeledMedication[];
  Active: LabeledMedication[];
}

export const isLabelMedActive = (lm: LabeledMedication) => {
  return lm.patientTaps.length || lm.activatedAt;
};
