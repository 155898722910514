import React from 'react';
import { CheckInsPageStyle } from '../styles';
import { userData } from 'services/states/auth';
import { Link } from 'react-router-dom';
import User from '../../Assets/user.svg';

const CheckIns = ({ activeTab, setTab }) => {
  const classes = CheckInsPageStyle();

  function Tabbing1() {
    setTab(false)
  }

  function Tabbing2() {
    setTab(true)
  }

  return (
    <div className={classes.topHeader}>
      <div className={classes.topPart}>
        <p className={classes.topP1}>Check-ins{userData.user?.firstName}</p>
      </div>
      <div className="topRight">
        <span className='user-icon'><Link to="/profile"><img src={User} alt="circle" /></Link></span>
      </div>
      <div className={classes.MedicationTab}>
        <ul className="tab-list">
          <li onClick={Tabbing1} className={!activeTab ? 'active' : ""}>Open</li>
          <li onClick={Tabbing2} className={activeTab ? 'active' : ""}>Completed</li>
        </ul>
      </div>
    </div>

  );
};

export default CheckIns;
