import FooterPageHtml from "app/containers/footer/UserListView/htm";
import React, { useEffect, useState } from "react";
import { MedicationPageStyle } from "./styles";
import moment from "moment";
import { getLabeledMedications } from "services/api/labelMedication";
import { LabeledMedication } from "../../../../entities/LabeledMedication";
import TapsCalendar from "./components/TapsCalendar";
import DailyTaps from "./components/DailyTaps";
import { getTapsHistory } from "services/api/tap";
import { getUserData } from "services/api/auth";
import { useHistory } from "react-router-dom";
import { Link } from "app/components/Link";
import User from "../Assets/user.svg";
import Bottom from "../Assets/bottom.svg";
import UpArrow from "../Assets/topArrow.svg";
import dateIcon from "../Assets/date.svg";
import { CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import { parse } from "date-fns";
import momentTimeZone from "moment-timezone";

export function TapsPageHtml() {
  const classes = MedicationPageStyle();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [isMonthly, setIsMonthly] = useState<boolean>(false);
  const [tapsHistory, settapsHistory] = useState<
    {
      needed: number;
      date: Date;
      tapped: number;
      unExpected: boolean | undefined;
    }[]
  >();
  const [activeLabeledMedications, setActiveLabeledMedications] = useState<
    LabeledMedication[]
  >();
  const [activeDailyTapsRemain, setActiveDailyTapsRemain] = useState(0);
  const [activeDailyTapsTotal, setActiveDailyTapsTotal] = useState(0);
  const [activeTapsHistory, setActivetapsHistory] = useState<
    { needed: number; date: Date; tapped: number }[]
  >();
  const [activeLmDailyTaps, setActiveLmDailyTaps] = useState<
    { needed: number; labelMedId: number; tapped: number }[]
  >();

  useQuery(["label medication", history], () => {
    activeMedicationData();
  });

  useEffect(() => {
    if (activeTapsHistory) {
      const daily = activeTapsHistory.find(
        (day) => day.date && moment().isSame(moment(day.date), "day")
      );
      if (!daily) return;
      setActiveDailyTapsRemain(daily?.needed - daily?.tapped);
      setActiveDailyTapsTotal(daily?.needed);
    }
  }, [activeTapsHistory]);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        await getUserData();
        history.replace({ pathname: "/taps" });
      } catch (err) {
        setLoading(false);
        history.push("/landing");
      }
    };
    checkLogin();
  }, [history]);

  const activeMedicationData = async () => {
    setLoading(true);
    try {
      const timeSpanType = isMonthly ? "month" : "week";
      const timeSpan = timeSpanType === "week" ? "week" : "month";

      const startActive = moment().startOf(timeSpanType).add(0, timeSpan);

      const endActive = moment().endOf(timeSpanType).add(0, timeSpan);

      const activeMedHistory = await getTapsHistory(startActive, endActive);

      const activeJoinedTapsNeededHistory: {
        date: Date;
        tapped: number;
        needed: number;
      }[] = [];
      activeMedHistory.forEach(({ taps }) => {
        taps.forEach(({ date, needed, tapped }) => {
          const prev = activeJoinedTapsNeededHistory.find((joined) =>
            moment(date).isSame(joined.date, "day")
          );
          if (prev) {
            prev.needed += needed ?? 0;
            prev.tapped += Math.min(tapped, needed) ?? 0;
          } else {
            activeJoinedTapsNeededHistory.push({ date, needed, tapped });
          }
        });
      });

      const activeLabelMeds = await getLabeledMedications();

      setActivetapsHistory(activeJoinedTapsNeededHistory);

      setActiveLmDailyTaps(
        activeMedHistory?.map(({ taps, labelMedId }) => {
          const { needed = 0, tapped = 0 } = {
            ...taps.find(({ date }) => moment().isSame(moment(date), "day")),
          };
          return { needed, tapped, labelMedId };
        })
      );

      setActiveLabeledMedications(
        activeLabelMeds.filter(
          (lm) =>
            !lm.discontinuedAt &&
            !lm.adjustedAt &&
            (!lm.endsAt || moment().isSameOrBefore(lm.endsAt))
        )
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    setLoading(false);
  };
  function is14DaysApart(date1, date2, findAmount) {
    const ONE_DAY = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const diff = Math.abs(date2 - date1);
    return diff >= findAmount * ONE_DAY;
  }

  const populatePrevTaps = async (
    isMonthly,
    calendarOffset,
    monthCalendarOffset,
    newLabelMeds
  ) => {
    const timeSpanType = isMonthly ? "month" : "week";
    const timeSpan = timeSpanType === "week" ? "week" : "month";
    const start = moment()
      .startOf(timeSpanType)
      .add(isMonthly ? monthCalendarOffset : calendarOffset, timeSpan);
    const end = moment()
      .endOf(timeSpanType)
      .add(isMonthly ? monthCalendarOffset : calendarOffset, timeSpan);
    const endDate = moment(end).endOf(timeSpanType);

    const medHistory = await getTapsHistory(start, endDate);
    const joinedTapsNeededHistory: {
      date: Date;
      tapped: number;
      needed: number;
      unExpected: boolean | undefined;
      timePeriod: number;
      id: number;
    }[] = [];

    medHistory.forEach(({ taps, labelMedId }) => {
      const findId = newLabelMeds?.find((val, i) => {
        return val.id == labelMedId;
      });

      let findAmount: any;

      if (findId?.time_period == 24 && findId?.amount > 1) {
        findAmount = findId?.amount;
        const patientTapsAsc = findId?.patientTaps?.length
          ? findId?.patientTaps?.sort((a, b) =>
              a.createdAt.localeCompare(b.createdAt)
            )
          : [];
        for (let i = 0; i < taps?.length; i++) {
          const currDate = patientTapsAsc?.length
            ? new Date(taps[i]?.date)
            : new Date(taps[i + 1]?.date);
          let prevDate = new Date(taps[i]?.date);
          patientTapsAsc?.length &&
            patientTapsAsc?.find((val, index) => {
              const getCurrentCountry = momentTimeZone.tz.guess();
              const dateString = momentTimeZone(val?.createdAt)
                .tz(getCurrentCountry)
                .format("D/M/YYYY, h:mm:ss A");

              const formatString = "d/M/yyyy, h:mm:ss a";
              const date = parse(dateString, formatString, new Date());

              if (new Date(date).getDate() == currDate.getDate()) {
                if (index > 0) {
                  const prevTapDate = momentTimeZone(
                    patientTapsAsc[index - 1]?.createdAt
                  )
                    .tz(getCurrentCountry)
                    .format("D/M/YYYY, h:mm:ss A");
                  // const prevTapDate = new Date(
                  //   patientTapsAsc[index - 1]?.createdAt
                  // ).toLocaleString();

                  prevDate = parse(prevTapDate, formatString, new Date());
                } else {
                  const prevTapDate = momentTimeZone(
                    patientTapsAsc[index - 1]?.createdAt
                  )
                    .tz(getCurrentCountry)
                    .format("D/M/YYYY, h:mm:ss A");

                  prevDate = parse(prevTapDate, formatString, new Date());
                }
              }
            });

          const expectedDate = new Date(
            prevDate.getTime() + findAmount * 24 * 60 * 60 * 1000
          );

          if (
            !is14DaysApart(prevDate, currDate, findAmount) &&
            currDate.getDate() !== expectedDate.getDate() &&
            prevDate.getDate() !== currDate.getDate() &&
            (taps[i]?.needed == taps[i]?.tapped ||
              taps[i]?.needed < taps[i]?.tapped)
          ) {
            taps[i].unExpected = true;
          } else {
            taps[i].unExpected = false;
          }
        }
        // Set unExpected to false for the remaining elements
        for (let i = 0; i < taps?.length; i++) {
          if (!taps[i]?.hasOwnProperty("unExpected")) {
            taps[i].unExpected = false;
          }
          const prev = joinedTapsNeededHistory?.find(
            (joined) =>
              moment(new Date()).isSame(moment(joined.date)) &&
              joined?.id === findId?.id
          );

          if (prev) {
            prev.needed += taps[i]?.needed ?? 0;
            prev.tapped += Math.min(taps[i]?.tapped, taps[i]?.needed) ?? 0;
          } else {
            joinedTapsNeededHistory.push({
              date: taps[i]?.date,
              needed: taps[i]?.needed,
              tapped: Math.min(taps[i]?.tapped, taps[i]?.needed),
              unExpected: taps[i]?.unExpected,
              timePeriod: findId?.time_period,
              id: findId?.id,
            });
          }
        }
      } else if (findId?.time_period == 168) {
        findAmount = findId?.amount * 7;
        const patientTapsAsc = findId?.patientTaps?.length
          ? findId?.patientTaps?.sort((a, b) =>
              a.createdAt.localeCompare(b.createdAt)
            )
          : [];
        for (let i = 0; i < taps?.length; i++) {
          const currDate = patientTapsAsc?.length
            ? new Date(taps[i]?.date)
            : new Date(taps[i + 1]?.date);
          let prevDate = new Date(taps[i]?.date);
          patientTapsAsc?.length &&
            patientTapsAsc?.find((val, index) => {
              const getCurrentCountry = momentTimeZone.tz.guess();
              const dateString = momentTimeZone(val?.createdAt)
                .tz(getCurrentCountry)
                .format("D/M/YYYY, h:mm:ss A");

              const formatString = "d/M/yyyy, h:mm:ss a";
              const date = parse(dateString, formatString, new Date());

              if (new Date(date).getDate() == currDate.getDate()) {
                if (index > 0) {
                  const prevTapDate = momentTimeZone(
                    patientTapsAsc[index - 1]?.createdAt
                  )
                    .tz(getCurrentCountry)
                    .format("D/M/YYYY, h:mm:ss A");
                  // const prevTapDate = new Date(
                  //   patientTapsAsc[index - 1]?.createdAt
                  // ).toLocaleString();

                  prevDate = parse(prevTapDate, formatString, new Date());
                } else {
                  const prevTapDate = momentTimeZone(
                    patientTapsAsc[index - 1]?.createdAt
                  )
                    .tz(getCurrentCountry)
                    .format("D/M/YYYY, h:mm:ss A");

                  prevDate = parse(prevTapDate, formatString, new Date());
                }
              }
            });

          const expectedDate = new Date(
            prevDate.getTime() + findAmount * 24 * 60 * 60 * 1000
          );

          if (
            !is14DaysApart(prevDate, currDate, findAmount) &&
            currDate.getDate() !== expectedDate.getDate() &&
            prevDate.getDate() !== currDate.getDate() &&
            (taps[i]?.needed == taps[i]?.tapped ||
              taps[i]?.needed < taps[i]?.tapped)
          ) {
            taps[i].unExpected = true;
          } else {
            taps[i].unExpected = false;
          }
        }

        // Set unExpected to false for the remaining elements
        for (let i = 0; i < taps.length; i++) {
          if (!taps[i]?.hasOwnProperty("unExpected")) {
            taps[i].unExpected = false;
          }
          const prev = joinedTapsNeededHistory?.find(
            (joined) =>
              moment(new Date()).isSame(moment(joined.date)) &&
              joined?.id === findId?.id
          );
          if (prev) {
            prev.needed += taps[i]?.needed ?? 0;
            prev.tapped += Math.min(taps[i]?.tapped, taps[i]?.needed) ?? 0;
          } else {
            joinedTapsNeededHistory.push({
              date: taps[i]?.date,
              needed: taps[i]?.needed,
              tapped: Math.min(taps[i]?.tapped, taps[i]?.needed),
              unExpected: taps[i]?.unExpected,
              timePeriod: findId?.time_period,
              id: findId?.id,
            });
          }
        }
      } else if (findId?.time_period == 1) {
        for (let i = 0; i < taps?.length; i++) {
          if (taps[i]?.tapped > taps[i]?.needed) {
            taps[i].unExpected = true;
          } else {
            taps[i].unExpected = false;
          }
        }

        // Set unExpected to false for the remaining elements
        for (let i = 0; i < taps.length; i++) {
          if (!taps[i]?.hasOwnProperty("unExpected")) {
            taps[i].unExpected = false;
          }
          const prev = joinedTapsNeededHistory?.find(
            (joined) =>
              moment(new Date()).isSame(moment(joined.date), "hour") &&
              joined?.id === findId?.id
          );

          if (prev) {
            prev.needed += taps[i]?.needed ?? 0;
            prev.tapped += Math.min(taps[i]?.tapped, taps[i]?.needed) ?? 0;
          } else {
            joinedTapsNeededHistory.push({
              date: taps[i]?.date,
              needed: taps[i]?.needed,
              tapped: Math.min(taps[i]?.tapped, taps[i]?.needed),
              unExpected: taps[i]?.unExpected,
              timePeriod: findId?.time_period,
              id: findId?.id,
            });
          }
        }
      } else if (findId?.time_period === 24 && findId?.amount === 1) {
        for (let i = 0; i < taps?.length; i++) {
          if (taps[i]?.tapped > taps[i]?.needed) {
            taps[i].unExpected = true;
          } else {
            taps[i].unExpected = false;
          }
        }

        // Set unExpected to false for the remaining elements
        for (let i = 0; i < taps?.length; i++) {
          if (!taps[i]?.hasOwnProperty("unExpected")) {
            taps[i].unExpected = false;
          }
          const prev = joinedTapsNeededHistory?.find(
            (joined) =>
              moment(new Date()).isSame(moment(joined.date)) &&
              joined?.id === findId?.id
          );

          if (prev) {
            prev.needed += taps[i]?.needed ?? 0;
            prev.tapped += Math.min(taps[i]?.tapped, taps[i]?.needed) ?? 0;
          } else {
            joinedTapsNeededHistory.push({
              date: taps[i]?.date,
              needed: taps[i]?.needed,
              tapped: Math.min(taps[i]?.tapped, taps[i]?.needed),
              unExpected: taps[i]?.unExpected,
              timePeriod: findId?.time_period,
              id: findId?.id,
            });
          }
        }
      } else {
        console.log("else");
        taps?.forEach(({ date, needed, tapped }) => {
          const prev = joinedTapsNeededHistory?.find(
            (joined) =>
              moment(new Date()).isSame(moment(joined.date)) &&
              joined?.id === findId?.id
          );
          if (prev) {
            prev.needed += needed ?? 0;
            prev.tapped += Math.min(tapped, needed) ?? 0;
          } else {
            joinedTapsNeededHistory.push({
              date,
              needed,
              tapped: Math.min(tapped, needed),
              unExpected: tapped > needed ? true : false,
              timePeriod: findId?.time_period,
              id: findId?.id,
            });
          }
        });
      }
    });
    // console.log("joi", joinedTapsNeededHistory);
    console.log("joinedTapsNeededHistory: ", joinedTapsNeededHistory);
    settapsHistory(joinedTapsNeededHistory);

    // const timeSpanType = isMonthly ? 'month' : 'week';
    // const timeSpan = timeSpanType === 'week' ? 'week' : 'month';
    // const start = moment().startOf(timeSpanType).add(isMonthly ? monthCalendarOffset : calendarOffset, timeSpan);
    // const end = moment().endOf(timeSpanType).add(isMonthly ? monthCalendarOffset : calendarOffset, timeSpan);
    // const endDate = moment(end).endOf(timeSpanType);

    // const medHistory = await getTapsHistory(start, endDate);

    // const joinedTapsNeededHistory: {
    //   date: Date;
    //   tapped: number;
    //   needed: number;
    // }[] = [];
    // medHistory.forEach(({ taps }) => {
    //   taps.forEach(({ date, needed, tapped }) => {
    //     const prev = joinedTapsNeededHistory.find(joined =>
    //       moment(date).isSame(joined.date, 'day'),
    //     );
    //     if (prev) {
    //       prev.needed += needed ?? 0;
    //       prev.tapped += Math.min(tapped, needed) ?? 0;
    //     } else {
    //       joinedTapsNeededHistory.push({ date, needed, tapped });
    //     }
    //   });
    // });
    // settapsHistory(joinedTapsNeededHistory);
  };

  return (
    <div className={classes.root}>
      <div className={classes.cover}>
        <div className={classes.topHeader}>
          <div className={classes.topPart}>
            <p className={classes.topP1}>Hello</p>
            <p className={classes.topP2}>
              {moment().format("dddd, MMMM D, h:mm	A")}{" "}
              <span className="date">
                <img src={dateIcon} alt="circle" />
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setIsMonthly(!isMonthly)}
              >
                <img src={!isMonthly ? Bottom : UpArrow} />
              </span>
            </p>
          </div>
          <div className="topRight">
            <span className="user-icon">
              <Link to="/profile">
                <img src={User} alt="circle" />
              </Link>
            </span>
          </div>
          <TapsCalendar
            tapsHistory={tapsHistory}
            refetch={populatePrevTaps}
            isMonthly={isMonthly}
          />
        </div>
        {loading ? (
          <CircularProgress
            style={{ color: "#F26F62", marginLeft: "40%", marginTop: "50%" }}
          />
        ) : (
          <div className={classes.coverPadding}>
            <DailyTaps
              dailyTapsRemain={activeDailyTapsRemain}
              dailyTapsTotal={activeDailyTapsTotal}
              labeledMedications={activeLabeledMedications}
              lmDailyTaps={activeLmDailyTaps}
            />
          </div>
        )}
      </div>

      <div className="footerDiv">
        <FooterPageHtml />
      </div>
    </div>
  );
}

export default TapsPageHtml;
