import React from 'react';
import { useHistory } from 'react-router-dom';
import Oval from '../../FinishPage/Assets/Group 4.png';
import { SurveyPageStyle } from './styles';

export function FinishpageHtml() {
  const classes = SurveyPageStyle();
  const history = useHistory();

  const handleFinish = e => {
    e.preventDefault();
    history.push('/taps');
  };

  return (
    <div className={classes.root}>
      <div className={classes.cover}>
        <div className={classes.topPart}>
          <img className={classes.innercircle} src={Oval} alt="circle"></img>
          <div className={classes.FinalText}>
            Thank you for completing the survey!
          </div>
        </div>

        <div className={classes.OptionCardView}></div>
      </div>
      <div className={classes.confirmButtonContainer}>
        <button className={classes.ConfirmButton} onClick={handleFinish}>
          Finish
        </button>
      </div>
    </div>
  );
}

export default FinishpageHtml;
