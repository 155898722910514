import axios from 'axios';
import moment from 'moment-timezone';
import { APP_API_URL } from 'utils/constants';

export const axiosInstance = axios.create({
  baseURL: APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': APP_API_URL,
    'Content-Type': 'application/json',
    'timezone': moment.tz.guess()
  } as never,
  withCredentials: true,
});
