import { makeStyles } from '@material-ui/core/styles';
import Path from '../../Assets/bg.png';

export const useSmStylesOtp = makeStyles(theme => ({
    root: {
        height: '100vh',
        background: '#fff',
        position: 'relative',
        textAlign: 'center',
    },
    login: {
        minHeight: '100vh',
        '& .topWrap': {
            height: '250px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: '50px 0 0',
            textAlign: 'center',
        },
        '& .topWrap h3': {
            margin: '0',
            width: '100%',
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '32px',
            color: '#201A18',
        },
        '& .topWrap h2': {
            margin: '20px 0',
            width: '100%',
        },
        '& .topWrap .icon': {
            width: '190px',
            height: '190px',
            display: ' inline-block',
            '& img': {
                width: '100%',
                height: '100%',
            },
        },
        '& .bottomWrap': {
            height: 'calc(100vh - 250px)',
            backgroundImage: `url('.${Path}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: ' top center',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .bottomWrap .loginDetail': {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'space-between',
            padding: ' 300px 16px 40px',
        },
        '& .bottomWrap .loginBtn': {
            width: '100%',
            marginBottom: '30px',
        },
        '& .bottomWrap .loginBtn .btn-login': {
            background: '#F26F62',
            borderRadius: '100px',
            fontWeight: '500',
            fontSize: '16px',
            letterSpacing: ' 0.1px',
            color: ' #FFFFFF',
            border: 'none',
            width: '100%',
            padding: '19px 10px',
        },
        '& .bottomWrap .copyRight': {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
        '& .bottomWrap .copyRight span': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '27px',
            color: '#6B6B6F',
        },
        '& .bottomWrap .copyRight span a': {
            color: '#6B6B6F',
        },
        '& .bottomWrap .copyRight p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '27px',
            color: '#676767',
            width: '100%',
            margin: '6px 0 0',
        },
        '& .bottomWrap .copyRight p a': {
            color: '#F26F62',
            textDecoration: 'none',
        },

    },

}));
