import { isLabelMedActive } from 'entities/LabeledMedication';
import _ from 'lodash';
import moment from 'moment';

export const renderMedicationTakeFreqString = (
  frequency: number,
  time_period: number,
  per_unit: number,
) => {
  const timeTypeToTextObj = {
    1: 'hour',
    24: 'day',
    168: 'week',
  };
  const timeTypeTxt =
    frequency === 1
      ? timeTypeToTextObj[time_period]
      : `${frequency} ${timeTypeToTextObj[time_period]}s`;
  return `${per_unit} unit${per_unit === 1 ? '' : 's'} every ${timeTypeTxt}`;
};

export const calcPerDay = (frequency: number, time_period: number) => {
  const perDay = 24 / (frequency * time_period);
  return perDay < 1 ? 0 : Math.round(perDay);
};

export const groupLebelMeds = (lmArr): any =>
  _.groupBy(lmArr, lm => {
    if (lm.adjustedAt) return 'Adjusted';
    if (lm.discontinuedAt) return 'Discontinued';
    if (lm.endsAt && moment().isSameOrAfter(lm.endsAt)) return 'Expired';
    if (!isLabelMedActive(lm)) return 'Awaiting First Tap';
    return 'Active';
  });
