import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Path from '../../Assets/bg.png';

export const useSmStylesOtp = makeStyles(theme => ({

  modal: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0 auto',
    maxWidth: '412px',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    textAlign: 'center',
    padding: ' 0 50px',
    display: 'none',
    '& .overlay': {
      position: 'absolute',
      content: ' "" ',
      backgroundColor: 'rgba(0,0,0,.6)',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: -1,
    },
    '& .modalContent': {
      background: 'linear-gradient(0deg, #F2F1F7, #F2F1F7), #FFFBFF',
      borderRadius: '28px',
      padding: '27px 24px 34px',
    },
  },
  modalActive: {
    display: 'flex',
  },

  forgotModal: {
    '& p': {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#201A18',
      textAlign: 'left',
      margin: '17px 0 38px',
    },
    '& .close-btn': {
      textAlign: 'right',
    },
    '& .close-btn button': {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#A83917',
      background: 'none',
      border: 'none',
      padding: '0',
    },

  },
  root: {
    height: '100vh',
    background: '#fdfdfd',
    position: 'relative',

  },

  login: {
    '& .topWrap': {
      height: '250px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      textAlign: 'center',
      padding: '0  16px',
    },
    '& .topWrap h2': {
      margin: '0 0 20px',
      width: '100%',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#201A18',
    },
    '& .topWrap p': {
      margin: '5px 0 0',
      width: '100%',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#201A18',
    },
    '& .topWrap .icon': {
      width: '160px',
      height: '160px',
      display: ' inline-block',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& .topWrap .back': {
      textAlign: 'left',
      width: '100%',
      padding: '25px 0 ',
    },
    '& .bottomWrap': {
      height: 'calc(100vh - 250px)',
      backgroundImage: `url('.${Path}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: ' top center',
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },


    '& .bottomWrap .loginDetail': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      padding: ' 190px 16px 40px',
    },
    '& .bottomWrap .loginBtn': {
      width: '100%',
      marginTop: '250px',
    },
    '& .bottomWrap .loginBtn .btn-login': {
      background: '#F26F62',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: ' 0.1px',
      color: ' #FFFFFF',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .copyRight': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '27px',
      color: '#676767',
      width: '100%',
    },
    '& .bottomWrap .forgot': {
      textAlign: 'center',
      marginTop: '40px',
    },
    '& .bottomWrap .forgot a': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#F26F62',
      letterSpacing: '0.25px',
      textDecoration: 'none',
    },
    '& .bottomWrap .btn-login': {
      background: 'rgba(31, 31, 31, 0.12)',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: ' 0.1px',
      color: '#191C1C',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .btn-active': {
      background: '#F26F62',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: ' 0.1px',
      color: '#fff',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .btn-login.active': {
      background: '#F26F62',
      color: ' #FFFFFF',
    },
  },

  questionList: {
    background: '#FFFFFF',
    border: '1px solid #FBDFDD',
    boxShadow: ' 0px 0px 4px 2px #FAE4E2',
    borderRadius: '12px',
    padding: '40px 20px',
    '& .note': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#666666',
      margin: '0',
    },
    '& .question': {
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '0.1px',
      color: '#201A18',
      margin: '10px 0 30px',
    },
    '& .option': {
      padding: '0',
      margin: '0',
      listStyleType: 'none',
    },
    '& .option li': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#201A18',
      backgroundColor: '#F2F1F7',
      boxShadow: '0px 0px 4px 1px #E5E5E6',
      borderRadius: '12px',
      padding: '20px',
      marginBottom: '20px',
    },
    '& .option li:last-child': {
      marginBottom: '0',
    },
    '& .option li.openActive': {
      backgroundColor: '#F26F62',
      color: '#ffffff',
    },
    '& .option li.completedActive': {
      backgroundColor: '#AFAEBB',
      color: '#ffffff',
    },
  },
  header: {
    display: 'inline-flex',
    marginTop: '24px',
    zIndex: 9,
    position: 'relative',
  },
  fillimage: {
    left: '-0.27%',
    right: ' 0%',
    top: '20.67%',
    bottom: ' 0%',
    background: 'rgba(255, 236, 197, 0.8)',
  },
  fillimages: {
    top: '0',
    left: '0%',
    right: '0',
    width: '100%',
    bottom: '0%',
    position: 'absolute',
    zIndex: 2,
    objectFit: 'cover',
  },
  newbackbtn: {
    width: '12px',
    height: '20px',
    marginTop: '11px',
  },
  banner: {
    flex: 1,
    padding: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    flexDirection: 'column',
  },
  paper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    minWidth: '350px',
    borderRadius: '3%',
    [theme.breakpoints.up('xs')]: {
      minWidth: '250px',
      flex: 3,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '250px',
      flex: 2,
    },
  },
  logoWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
  },

  bannerTextWrap: {
    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: '#fff',
  },
  bannerText1: {
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    lineHeight: '41px',
    fontSize: '26px',
    marginLeft: '19px',
    letterSpacing: '0.548148px',
    color: '#4D4D5A',
  },


}));



export const useStylesOtp = makeStyles(theme => ({

  form: {
    width: '100%',
    '& .form-group': {
      marginBottom: '30px',
      '& .MuiFormControl-fullWidth': {
        margin: '0',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#6F7978',
        borderRadius: '4px',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        fontWeight: '400',
        fontSize: '16px',
        color: '#A83917',
        backgroundColor: '#fff',
      },
      '& .MuiInputLabel-formControl': {
        fontWeight: '400',
        fontSize: '16px',
        color: '#8E9190',
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#A83917',
      },
    },
    '& .phone-group': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& .MuiOutlinedInput-input': {
        padding: '18.5px 44px 18.5px 14px',
      },
      '& .icon': {
        position: 'absolute',
        right: '15px',
        top: '0',
        bottom: '0',
        margin: 'auto 0',
        width: '20px',
        height: '20px',
      },

      '& .react-tel-input .flag-dropdown': {
        backgroundColor: '#fff',
        border: '1px solid #6F7978',
        minWidth: '56px',
        height: '56px',
        position: 'static',
        borderRadius: '4px',
        marginRight: '15px',
      },
      '& .react-tel-input .selected-flag': {
        margin: 'auto',
        padding: '10px 6px',
      },
      '& .react-tel-input .country-list': {
        zIndex: '9',
      },
      '& .react-tel-input ': {
        display: "flex",
        flexDirection: 'row-reverse',
      },
      '& .react-tel-input .form-control': {
        height: "56px",
        width: "100% ",
        fontSize: '16px',
        padding: '10px 12px',
        border: '1px solid #6F7978',
        transition: 'all 0.2s ease-in-out',
      },
      '& .react-tel-input .special-label': {
        display: 'block',
        color: ' #8E9190',
        fontSize: '16px',
        fontWeight: '400',
        left: '68px',
        top: '0',
        backgroundColor: '#fff',
        // transform:' translate(14px, 16px) scale(1)',
        transform: 'translate(5px, -11px) scale(0.75)',
        transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        zIndex: '1',
        padding: '0 5px',
      },
    },
    '& .phone-group.focus-group': {
      '& .react-tel-input .special-label': {
        transform: 'translate(5px, -11px) scale(0.75)',
        color: '#A83917',
      },
      '& .react-tel-input .form-control ': {
        border: '2px solid #A83917',
      },
    },
    '& .phone-group.remove-group': {
      '& .react-tel-input .special-label': {
        display: 'none',
      },
    },

  },
  loginButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  inputBox: {
    maxWidth: '343px',
    width: '100%',
    color: '#424248',
    '& .MuiInputBase-input': {
      color: '#424248',
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      color: '#FC8263',
    },
  },
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 1),
    },
  },
  forgotPassword: {
    color: '#1F8EFA',
    fontSize: '14px',
    textDecoration: 'underline',
  },
  newbutton: {
    maxWidth: '343px',
    width: '100%',
    marginTop: '20px',
    fontSize: '18px',
    fontWeight: 600,
    color: 'white',
    background: 'linear-gradient(140.62deg, #F97C74 -12%, #F86060 100%)',
  },
  newInput: {
    left: '16px',
    right: '16px',
    top: '128px',
    bottom: '634px',
    padding: '10px',
    width: '300px',
    background: '#FFFFFF',
    border: '1px solid #FC8263',
    boxShadow: '0px 9px 24px rgba(45, 45, 52, 0.15)',
    borderRadius: '10px',
  },
  countryList: {
    '& .country': {
      textAlign: 'left',
    },
  },
  textfieldClass: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  }
}));
