import React, { useEffect, useState } from 'react';
import { CheckInsPageStyle } from '../styles';
import Leftarrow from '../../Assets/left.svg';
import Rightarrow from '../../Assets/rightchevron.svg';
import Check from '../../Assets/check.svg';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { Link, useHistory, useParams } from 'react-router-dom';
import { IAssignedSurvey } from 'entities/Survey';
import { createPatientAnswer } from 'services/api/patientAnswers';
import { getAssignedSurvey } from 'services/api/assignedSurvey';

interface Answer {
  id: number;
  answer: string;
  alert: boolean;
}

interface Question {
  id: number;
  name: string;
  category: string;
  type: 'SINGLE' | 'MULTIPLE';
  answers: Answer[];
  patientAnswers: any;
}

const Question = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>();
  const classes = CheckInsPageStyle();

  const [activemodal, setActiveModal] = useState<boolean>(false)
  const [formValues, setFormValues] = useState({});
  const [
    assignedSurvey,
    setAssignedSurvey,
  ] = useState<IAssignedSurvey | null>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [totalQuestionsNumber, setTotalQuestionsNumber] = useState<number | any>();
  const [showActive, setShowActive] = useState<boolean | number>(false);


  useEffect(() => {
    // get current question
    setCurrentQuestion(assignedSurvey?.survey?.questions[currentQuestionIndex]);
    const answers = assignedSurvey?.answers.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.question.id]: [
          ...(acc[curr.question.id] ?? []),
          curr.answer.id.toString(),
        ],
      };
    }, {});
    setFormValues(answers);
  }, [currentQuestionIndex, assignedSurvey]);

  useEffect(() => {
    // start survey
    setCurrentQuestionIndex(0);
    setTotalQuestionsNumber(assignedSurvey?.survey?.questions?.length ?? 0);
  }, [assignedSurvey]);

  useEffect(() => {
    // get survey
    getSurveyData(id);
  }, []);

  const updateSingleQuestionValue = (qId, val) => {
    setFormValues(prev => ({ ...prev, [qId]: [val.toString()] }));
  };

  const updateMultiQuestionValue = (qId, val) => {
    if (!formValues[qId]?.includes(val)) {
      setFormValues(prev => ({
        ...prev,
        [qId]: [...(prev[qId] ?? []), val],
      }));
    } else {
      setFormValues(prev => ({
        ...prev,
        [qId]: [...(prev[qId] ?? []).filter(v => v !== val)],
      }));
    }
  };

  const submitQuestion = async () => {
    if (assignedSurvey?.isClosed) {
      if (currentQuestionIndex + 1 === totalQuestionsNumber) {
        history.push('/check-ins')
        return
      } else {
        return
      }
    }
    if (!assignedSurvey || !assignedSurvey?.id) return;
    const question = assignedSurvey?.survey?.questions[currentQuestionIndex];
    if (!question || !formValues[question.id]) return;
    const answers = formValues[question.id]
      .map(v => parseInt(v))
      ?.filter(v => v);
    if (!answers) {
      return;
    }
    await createPatientAnswer(question.id, answers, assignedSurvey.id);
    setShowActive(false)
  };

  const getSurveyData = async id => {
    const assignedSurvey = await await getAssignedSurvey(id);
    setAssignedSurvey(assignedSurvey);
  };

  const submitData = async () => {
    await submitQuestion();
    setActiveModal(true)
  };

  const nextQuestion = async () => {
    await submitQuestion();
    setCurrentQuestionIndex(prev => prev + 1);
  };

  const submitSinMulData = (e, val, i) => {
    setShowActive(i)
    if (currentQuestion?.type === 'MULTIPLE') {
      updateMultiQuestionValue(
        currentQuestion?.id,
        e.target.textContent,
      )
    } else {
      updateSingleQuestionValue(
        currentQuestion?.id,
        val.id.toString(),
      )
    }
  }


  return (
    <>
      <div className={classes.root} >
        <div className={classes.cover} >
          <div className={classes.subHeader} >
            <p className={classes.topP2}><Link to="/check-ins"><img src={Leftarrow} />{assignedSurvey?.survey?.name}</Link></p>
            <span className='question-number'> {(currentQuestionIndex || currentQuestionIndex === 0) &&
              totalQuestionsNumber
              ? `${currentQuestionIndex + 1}/${totalQuestionsNumber}`
              : ''}</span>
            <div className='progress'>
              <ProgressBar
                filledBackground={assignedSurvey?.isClosed ? "#CECCD9" : "#FF7851"}
                percent={(currentQuestionIndex + 1 / totalQuestionsNumber) * 100}
              />
            </div>
            <div className={classes.coverPadding}>
              <div className={classes.questionList}>
                <p className='note'>Select an answer</p>
                <p className='question'>{currentQuestion?.name}</p>
                <ul className='option'>

                  {
                    currentQuestion?.answers?.map((val, i) => {
                      if (!assignedSurvey?.isClosed) {
                        return (
                          <li key={val?.id} className={showActive === i ? 'openActive' : ''} onClick={(e) => submitSinMulData(e, val, i)}>{val?.answer}</li>
                        )
                      } else {
                        const findMatchingIdData = assignedSurvey?.answers &&
                          assignedSurvey?.answers.find(
                            pa =>
                              pa.question.id ===
                              currentQuestion?.id &&
                              pa.answer.id === val?.id,
                          )
                        return (
                          <li key={val?.id} className={findMatchingIdData?.answer?.id === val?.id && findMatchingIdData?.question?.id === currentQuestion?.id ? 'completedActive' : ''} >{val?.answer}</li>
                        )
                      }
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="subFooter">
            <div className='btnList flex-end'>
              {
                totalQuestionsNumber &&
                  (currentQuestionIndex + 1) < totalQuestionsNumber ? (
                  <button onClick={nextQuestion} >Next <img src={Rightarrow} /></button>
                ) :
                  (
                    <button className='confrim' onClick={submitData} >{assignedSurvey?.isClosed ? 'Close' : 'Confirm'}</button>
                  )
              }
            </div>
          </div>
        </div>

        {/* Modal   */}
        <div className={`${classes.modal} ${classes.questionModal} ${activemodal ? classes.active : ""}`}  >
          <div className='overlay' onClick={e => { e.stopPropagation(); }}></div>
          <div className='modalContent'>
            <span className='icon'><img src={Check} /></span>
            <p className='desc'>Your response has been recorded in the system.</p>
            <div className='close-btn'>
              <button onClick={() => { setActiveModal(false); history.push('/check-ins') }}>Close</button>
            </div>
          </div>
        </div>
      </div >
    </>
  )
};

export default Question
