import { LabeledMedication } from 'entities/LabeledMedication';
import { axiosInstance } from '.';

export const getLabeledMedications = async (): Promise<LabeledMedication[]> => {
  const rsp = await axiosInstance.get('labeledMedication/patient');
  return rsp.data;
};

export async function POSTadherenceByRange({
  start,
  end,
  patientIds,
}: {
  start?;
  end;
  patientIds?;
}) {
  const res = await axiosInstance.post('/patienttap/adherence', { start, end, patientIds });
  return res.data;
}
