import { makeStyles } from '@material-ui/core/styles';
import Path from '../../Assets/bg.png';

export const useSmStylesOtp = makeStyles(() => ({
  root: {
    height: '100vh',
    background: '#fff',
    position: 'relative',
    textAlign: 'center',
  },
  login: {
    minHeight: '100vh',
    '& .topWrap': {
      height: '330px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '100px 0 0',
      textAlign: 'center',
    },
    '& .topWrap h2': {
      margin: '20px 0 0  ',
      width: '100%',
    },
    '& .topWrap .icon': {
      width: '190px',
      height: '190px',
      display: ' inline-block',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& .bottomWrap': {
      height: 'calc(100vh - 330px)',
      backgroundImage: `url('.${Path}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: ' top center',
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .bottomWrap .loginDetail': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      padding: ' 210px 16px 40px',
    },
    '& .bottomWrap .loginBtn': {
      width: '100%',
      marginBottom: '30px',
    },
    '& .bottomWrap .loginBtn .btn-login': {
      background: '#F26F62',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: ' 0.1px',
      color: ' #FFFFFF',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .copyRight': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '27px',
      marginBottom: '25px',
      color: '#676767',
      width: '100%',
    },
    '& .bottomWrap .copyRight a': {
      color: '#F26F62',
      textDecoration: 'none',
    },
  },

}));
