import { AxiosError } from 'axios';
import { axiosInstance } from '.';

export const login = async (phone: string, password: string) => {
  const rsp = await axiosInstance.post('auth/login', { phone, password });
  return rsp.data;
};

export const forgotPassword = async (phone: string) => {
  const rsp = await axiosInstance.patch('auth/forgot_patient_password', {
    phone,
  });
  return rsp.data;
};

export const confirmOtp = async (otp: number, token: string) => {
  const rsp = await axiosInstance.post('auth/otp/confirm', { otp, token });
  return rsp.data;
};

export const setPatientPssword = async (password: string, token: string) => {
  try {
    const rsp = await axiosInstance.patch('auth/setpatientpassword', {
      password,
      token,
    });
    return rsp.data;
  } catch (error) {
    if (typeof error === 'string') throw error;
    if (error instanceof AxiosError)
      throw (
        error.response?.data?.message ||
        error.message ||
        'failed setting new password'
      );
  }
};
export const logOut = async () => {
  await axiosInstance.get('auth/logout');
  localStorage.clear();
  document.location.href = '/login';
};
export const getUserData = async () => {
  const rsp = await axiosInstance.get('auth/me');
  return rsp.data;
};
