import moment, { Moment, unitOfTime } from 'moment';

const longDateFormat = 'MM/DD/YY , h:mm a';
const shortDateFormat = 'MM/DD/YY';
const hourFormat = 'h:mm a';

export const formatLongDate = (date: Date | Moment): string =>
  date ? moment(date).format(longDateFormat) : '-';

export const formatShortDate = (date: Date | Moment): string =>
  date ? moment(date).format(shortDateFormat) : '-';

export const formatHour = (date: Date | Moment): string =>
  date ? moment(date).format(hourFormat) : '-';

export const createDateArrayFromRange = (
  start: Date,
  end: Date,
  offset: number,
  time_period: unitOfTime.Base,
) => {
  const duration = moment.duration(moment(end).add(1, 'days').diff(start));
  return new Array(Math.floor(duration.as(time_period) / offset))
    .fill(true)
    .map((_, i) =>
      moment(start)
        .add(i * offset, time_period)
        .toDate(),
    );
};

export const createISOWeekArray = (offset: number = 0) => {
  return createDateArrayFromRange(
    moment().add(offset, 'week').startOf('week').toDate(),
    moment().add(offset, 'week').endOf('week').toDate(),
    1,
    'day',
  );
};
