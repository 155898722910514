import { proxy } from 'valtio';

export const userData: {
  isLoggedIn: boolean;
  user?: {
    alertTypes: string;
    createdAt: Date;
    discipline: string;
    email: string;
    firstName: string;
    lastName: string;
    phone_number: string;
    speciality: string;
    updatedAt: Date;
  };
} = proxy({
  isLoggedIn: true,
});
