import { Link, useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSmStylesOtp, useStylesOtp } from './styles';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { setPatientPssword } from 'services/api/auth';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import logoIcon from '../../Assets/logo-icon.png';
import Left from '../../Assets/left.svg';
import Error from '../../Assets/error.svg';


export function ComponentPage() {
  const classes = useStylesOtp();
  const classesSm = useSmStylesOtp();
  const { token } = useParams<{ token: string }>();
  let history = useHistory();

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('error');
  const [newErrMsg, setNewErrMsg] = useState<number>(0)
  const [errorButton, setErrorButton] = useState(false)
  const [changeColor, setChangeColor] = useState(false)

  const handleChange = (e) => {
    setPassword(e.target.value)
    if (e.target.value.length < 8) {
      setChangeColor(false)
      setNewErrMsg(1)
      setErrorButton(true)
      return
    } else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+`~])/.test(e.target.value)) {
      setChangeColor(false)
      setNewErrMsg(2)
      setErrorButton(true)
      return
    } else {
      setNewErrMsg(0)
      setErrorButton(false)
      return
    }
  }

  const repeatPassChange = (e) => {
    setPasswordRepeat(e.target.value);
    if (e.target.value && password == e.target.value) {
      setChangeColor(true);
      return
    }
    else {
      setChangeColor(false)
    }
  }

  const submitData = async (e) => {
    e.preventDefault()
    if (password.length < 8) {
      setNewErrMsg(1)
      setErrorButton(true)
      return
    } else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+`~])/.test(password)) {
      setNewErrMsg(2)
      setErrorButton(true)
      return
    } else if (!!passwordRepeat && password !== passwordRepeat) {
      return
    }
    try {
      setIsLoading(true);
      await setPatientPssword(password, token);
      history.push('/login');
    } catch (err) {
      if (typeof err === 'string') setErrorMsg(err);
      else setErrorMsg('failed setting new password');
      setIsFetchError(true);
    }
    setIsLoading(false);
  };

  return (
    <div className={classesSm.root}>
      <div className={classesSm.login}>
        <div className='topWrap'>
          <div className='back'>
            <Link to="/"><img src={Left} /></Link>
          </div>
          <h2>Set your password
          </h2>
          <span className='icon'><img
            src={logoIcon}
            alt="welcome icon"
          ></img></span>
        </div>
        <div className='bottomWrap'>
          <div className='loginDetail'>
            <form className={classes.form} noValidate>
              <div className='form-group'>
                <div className='phone-group'>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    label={'New password'}
                    name="password"
                    type="password"
                    autoComplete="password"
                    autoFocus
                    onChange={handleChange}
                  />
                  <span className='icon'>
                    {errorButton ? <img src={Error} /> : ""}
                  </span>
                </div>
              </div>
              <div className='form-group'>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="repeat_password"
                  label={'Confirm password'}
                  error={!!passwordRepeat && password !== passwordRepeat}
                  helperText={!!passwordRepeat && password !== passwordRepeat ? 'Confirm Password Must Match With New Password' : ''}
                  type="password"
                  id="repeat_password"
                  autoComplete="password"
                  onChange={(e) => repeatPassChange(e)}
                />
              </div>
              <div className='form-group'>
                <h2 className='hedaingError'>Password must:</h2>
                <ul className='listError'>
                  <li className={newErrMsg === 1 ? 'Error' : ''}>Be at least 8 characters</li>
                  <li className={newErrMsg === 2 ? 'Error' : ''}>Including all of the following:
                    <ul>
                      <li >An uppercase character</li>
                      <li>A lowercase character</li>
                      <li>A number</li>
                      <li>A special character</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className='form-group'>
                <div className={classes.loginButtonWrapper}>
                  <button className={changeColor ? 'btn-active' : 'btn-login'} onClick={submitData}>Update</button>
                </div>
              </div>
              {isLoading ? (
                <CircularProgress size={24} className={classes.buttonProgress} />
              ) : (
                isFetchError && (
                  <Snackbar
                    open={isFetchError}
                    autoHideDuration={3000}
                    onClose={() => setIsFetchError(false)}
                    message={errorMsg}
                  >
                    <Alert severity="error">{errorMsg}</Alert>
                  </Snackbar>
                )
              )}
            </form>
          </div>
        </div>
      </div >
    </div >
  );
}
export default ComponentPage;
