import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSmStylesOtp, useStylesOtp } from './styles';
import { forgotPassword } from 'services/api/auth';
import { userData } from 'services/states/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Snackbar } from '@material-ui/core';
import logoIcon from '../../Assets/logo-icon.png';
import Forgot from '../../Assets/forgot.svg';
import Left from '../../Assets/left.svg';
import Cancle from '../../Assets/cancle.svg';
import { Alert } from '@material-ui/lab';

const OutSideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref?.current && !ref?.current?.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export function ComponentPage() {
  const classesSm = useSmStylesOtp();
  const classes = useStylesOtp();
  let history = useHistory();
  const creatdRef = useRef<HTMLDivElement>(null)
  const [phone, setPhone] = useState('');
  const [isFetchError, setIsFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [closeButton, setCloseButton] = useState(false)
  const [errorMsg, setErrorMsg] = useState('error');
  const [activemodal, setActiveModal] = useState<boolean>(false)
  const [focus, setFocus] = useState(false)
  const [placeHolder, setPlaceHolder] = useState(false)
  const [changeColor, setChangeColor] = useState(false)

  const submitData = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true);
      const patient = await forgotPassword(phone);
      userData.isLoggedIn = true;
      userData.user = patient;
      setIsLoading(false);
      setIsFetchError(false);
      setActiveModal(true)
    } catch (err: any) {
      if (err) {
        setErrorMsg(err?.response?.data?.message || 'Error');
        setIsFetchError(true);
      } else {
        setErrorMsg('Error');
        setIsFetchError(true);
      }
      setIsLoading(false);
    }
  };

  const phoneOnChange = (val) => {
    setChangeColor(true)
    setPhone(val);
    setCloseButton(true);
    if (val === "") {
      setChangeColor(false)
      setPlaceHolder(true);
      setCloseButton(false)
    };
    setPlaceHolder(false);
  }

  const closeButtonFunc = () => {
    setPhone("");
    setCloseButton(false);
    if (!focus) { setPlaceHolder(true); };
  }

  OutSideClick(creatdRef, () => {
    if (phone === '') {
      setPlaceHolder(true)
    }
    setFocus(false);
  });


  return (
    <>
      <div className={classesSm.root}>
        <div className={classesSm.login}>
          <div className='topWrap'>
            <div className='back'>
              <Link to="/"><img src={Left} /></Link>
            </div>
            <h2>Forgot Password
              <p className='desc'>Enter your phone number to receive a verification text to reset your password</p>
            </h2>
            <span className='icon'><img
              src={logoIcon}
              alt="welcome icon"
            ></img></span>
          </div>
          <div className='bottomWrap'>
            <div className='loginDetail'>
              <form className={classes.form} noValidate>
                <div className={`form-group phone-group ${focus ? 'focus-group' : ''} ${placeHolder ? 'remove-group' : ''}`} ref={creatdRef}>
                  <PhoneInput
                    onFocus={() => { setPlaceHolder(false); setFocus(true) }}
                    country={'us'}
                    placeholder={!focus ? "Phone" : ""}
                    value={phone}
                    onChange={phoneOnChange}
                    dropdownClass={classes.countryList}
                    masks={{ pr: '(...) ...-....' }}
                  />
                  <span className="icon" onClick={closeButtonFunc}>
                    {closeButton ? <img src={Cancle} /> : ""}
                  </span>
                </div>
                <div className='form-group'>
                  <div className={classes.loginButtonWrapper}>
                    <button className={changeColor ? 'btn-active' : 'btn-login'} onClick={submitData}>Next</button>
                  </div>
                </div>
                {isLoading ? (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                ) : (
                  isFetchError && (
                    <Snackbar
                      open={isFetchError}
                      autoHideDuration={3000}
                      onClose={() => setIsFetchError(false)}
                      message={errorMsg}
                    >
                      <Alert severity="error">{errorMsg}</Alert>
                    </Snackbar>
                  )
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`${classesSm.modal} ${classesSm.forgotModal} ${activemodal ? classesSm.modalActive : ""}`}>
        <div className='overlay'></div>
        <div className='modalContent'>
          <span className='icon'><img src={Forgot} /></span>
          <p className='desc'>A link to reset your password was sent to you via SMS text message.</p>
          <div className='close-btn'>
            <button onClick={() => history.push('/login')}>Got it</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComponentPage;
