import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSmStylesOtp, useStylesOtp } from './styles';
import { getUserData, login } from 'services/api/auth';
import { userData } from 'services/states/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Snackbar, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import logoIcon from '../../Assets/logo-icon.png';
import Cancle from '../../Assets/cancle.svg';
import Left from '../../Assets/left.svg';
import EventTrack from 'app/components/EventTrack';


const OutSideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref?.current && !ref?.current?.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export function ComponentPage() {
  const { t } = useTranslation();
  const classesSm = useSmStylesOtp();
  const classes = useStylesOtp();
  let history = useHistory();
  const creatdRef = useRef<HTMLDivElement>(null)
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('')
  const [isFetchError, setIsFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('error');
  const [closeButton, setCloseButton] = useState(false);
  const [focus, setFocus] = useState(false);
  const [placeHolder, setPlaceHolder] = useState(false);
  const [changeColor, setChangeColor] = useState(false);

  useEffect(() => {
    if (phone && password) {
      setChangeColor(true)
    } else {
      setChangeColor(false)
    }
  }, [phone, password])

  const submitData = async (e) => {
    EventTrack('login', 'login action', 'button')
    e.preventDefault()
    try {
      setIsLoading(true);
      const patient = await login(phone, password);
      await getUserData();
      userData.isLoggedIn = true;
      userData.user = patient;
      setIsFetchError(false);
      setIsLoading(false);
      history.push('/taps');
    } catch (err: any) {
      if (err) {
        setErrorMsg(err?.response?.data?.message || 'Error');
        setIsFetchError(true);
      } else {
        setErrorMsg('Error');
        setIsFetchError(true);
      }
      setIsLoading(false);
    }
  };

  const phoneOnChange = (val) => {
    setPhone(val);
    setCloseButton(true);
    if (val === "") {
      setPlaceHolder(true);
      setCloseButton(false)
    };
    setPlaceHolder(false);
  }

  OutSideClick(creatdRef, () => {
    if (phone === '') {
      setPlaceHolder(true)
    }
    setFocus(false);
  });

  const closeButtonFunc = () => {
    setPhone("");
    setCloseButton(false);
    if (!focus) { setPlaceHolder(true); };
  }

  return (
    <div className={classesSm.root}>
      <div className={classesSm.login} >
        <div className='topWrap'>
          <div className='back'>
            <Link to="/landing"><img src={Left} /></Link>
          </div>
          <h2>Enter your phone number and password
          </h2>
          <span className="icon" ><img
            src={logoIcon}
            alt="welcome icon"
          ></img></span>
        </div>
        <div className='bottomWrap'>
          <div className='loginDetail'>
            <form className={classes.form} noValidate>
              <div className={`form-group phone-group ${focus ? 'focus-group' : ''} ${placeHolder ? 'remove-group' : ''}`} ref={creatdRef}>
                <PhoneInput
                  onFocus={() => { setPlaceHolder(false); setFocus(true) }}
                  country={'us'}
                  placeholder={!focus ? "Phone" : ""}
                  value={phone}
                  onChange={phoneOnChange}
                  dropdownClass={classes.countryList}
                  masks={{ pr: '(...) ...-....' }}
                />
                <span className="icon" onClick={closeButtonFunc}>
                  {closeButton ? <img src={Cancle} /> : ""}
                </span>
              </div>
              <div className='form-group'>
                <TextField
                  value={password}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  label={t<string>('LOGIN.PASSWORD')}
                  name="password"
                  type="password"
                  autoComplete="password"
                  autoFocus
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <div className={classes.loginButtonWrapper}>
                  <button className={changeColor ? 'btn-active' : 'btn-login'} onClick={submitData}>Login</button>
                </div>
              </div>
              <div className='form-group'>
                <div className='forgot'>
                  <Link to={'/forgot_password'} className="">Forgot password?</Link>
                </div>
              </div>
              {isLoading ? (
                <CircularProgress size={24} className={classes.buttonProgress} />
              ) : (
                isFetchError && (
                  <Snackbar
                    open={isFetchError}
                    autoHideDuration={3000}
                    onClose={() => setIsFetchError(false)}
                    message={errorMsg}
                  >
                    <Alert severity="error">{errorMsg}</Alert>
                  </Snackbar>
                )
              )}
            </form>
          </div>
        </div>
      </div >
    </div >
  );
}

export default ComponentPage;
