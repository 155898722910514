import { axiosInstance } from '.';

export const getAssignedSurvey = async id => {
  const rsp = await axiosInstance.get(`/survey-assigned/${id}`);
  return rsp.data;
};

export const getAllAssignedSurveys = async () => {
  const rsp = await axiosInstance.get('/survey-assigned');
  return rsp?.data?.length ? rsp?.data : [];
};
