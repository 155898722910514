import { makeStyles } from '@material-ui/core/styles';

import Path from '../../Auth/Assets/bg.png';

export const SurveyPageStyle = makeStyles(theme => ({
  root: {
    height: '100vh',
    background: '#fff',
    position: 'relative',
    textAlign: 'center',
  },
  login: {
    minHeight: '100vh',
    '& .topWrap': {
      height: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '50px 0 0',
      textAlign: 'center',
    },
    '& .topWrap h2': {
      margin: '0 0 20px',
      width: '100%',
      fontWeight: '500',
      fontSize: '48px',
      color: '#F26F62',
    },
    '& .topWrap h2 p': {
      margin: '5px 0 0',
      width: '100%',
      fontWeight: '400',
      fontSize: '24px',
      color: '#201A18',
    },
    '& .topWrap .icon': {
      width: '210px',
      height: '210px',
      display: ' inline-block',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& .bottomWrap': {
      minHeight: 'calc(100vh - 300px)',
      backgroundImage: `url('.${Path}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: ' top center',
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .bottomWrap .loginDetail': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      padding: '170px 16px',
    },
    '& .bottomWrap .loginBtn': {
      width: '100%',
    },
    '& .bottomWrap .loginBtn .btn-login': {
      background: '#F26F62',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: ' 0.1px',
      color: ' #FFFFFF',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .copyRight': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '27px',
      color: '#676767',
      width: '100%',
    },

  },
}));
