import { axiosInstance } from '.';

interface Alert {
  name: string;
  type: string;
}

export const createAlert = async (alert: Alert) => {
  const rsp = await axiosInstance.post('/alerts', alert);
  return rsp.data;
};
