import React, { useState } from 'react';
import { ProfilePageStyle } from '../styles';
import Leftarrow from '../../Assets/left.svg';
import reportIcon from '../../Assets/report.svg';
import { Link, useHistory } from 'react-router-dom';
import { createAlert } from 'services/api/alerts';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const ReportError = () => {
  const classes = ProfilePageStyle();
  const history = useHistory()

  const optionArray = ['My contact information', 'My clinical site', 'My medication plan', 'Other']
  const [activemodal, setActiveModal] = useState<boolean>(false)
  const [getOption, setGetOption] = useState<boolean | number>(false)
  const [getOptionValue, setGetOptionValue] = useState<string>('')
  const [showNotifyTeam, setShowNotifyTeam] = useState<boolean>(false);
  const [notifyMsg, setNotifyMsg] = useState<string>('');
  const [isNotifyError, setIsNotifyError] = useState<boolean>(false);

  const notifyTeam = async () => {
    if (!getOptionValue) {
      setNotifyMsg('Please select the information which seems incorrect to you.')
      setShowNotifyTeam(true);
      setIsNotifyError(true);
      return
    }
    try {
      await createAlert({
        name: getOptionValue,
        type: 'Error reported by patient',
      });
      setActiveModal(true)
    } catch (err) {
      if (err instanceof Error) setNotifyMsg(err.message);
      setShowNotifyTeam(true);
      setIsNotifyError(true);
    }
  };

  return (
    <>
      <div className={classes.root} >
        <div className={classes.cover} >
          <div className={classes.topHeader}>
            <div className={classes.topPart}>
              <p className={classes.topP2}><Link to="/profile"><img src={Leftarrow} />Report an error</Link></p>
            </div>
          </div>
          <div className={classes.coverPadding}>
            <div className={classes.reportWrap}>
              <h2 className='report-title'>Something doesn't seem right in your profile or account?</h2>
              <p className='report-note'>Your clinical team will be notified to review your account.</p>
              <div className='reportList'>
                <p className='question'>Which of the following information doesn't seem to be correct?</p>
                <ul className='option'>
                  {
                    optionArray?.length && optionArray.map((val, i) => {
                      return (
                        <li key={i} onClick={() => { setGetOption(i); setGetOptionValue(val) }} className={getOption === i ? 'openActive' : ''}>{val}</li>
                      )
                    }
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="subFooter">
          <div className='btnList flex-end'>
            <button className="confrim" onClick={notifyTeam}>Submit</button>
          </div>
        </div>
      </div>
      <Snackbar
        open={showNotifyTeam}
        autoHideDuration={2000}
        onClose={() => setShowNotifyTeam(false)}
        className={classes.notification}
      >
        <Alert severity={isNotifyError ? 'error' : 'success'}>
          {notifyMsg}
        </Alert>
      </Snackbar>

      {/* --- Modal  --- */}
      <div className={`${classes.modal} ${classes.reportModal} ${activemodal ? classes.modalActive : ""}`}>
        <div className='overlay'></div>
        <div className='modalContent'>
          <span className='icon'><img src={reportIcon} /></span>
          <p className='desc'>Your clinical team was notified to review your account. You will be contacted if needed.</p>
          <div className='close-btn'>
            <button onClick={() => { setActiveModal(false); history.push('/profile') }}>Got it</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportError;
