import { makeStyles } from '@material-ui/core/styles';


export const MedicationPageStyle = makeStyles(theme => ({
  root: {
    fontFamily: 'Roboto',
    height: '100vh',
    '& .footerDiv': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '90px',
      zIndex: '9999',
      padding: '12px 15px',
      maxWidth: '412px',
      textAlign: 'center',
      margin: '0px auto',
      background: '#F8F6F7',
      '& .buttons': {
        justifyContent: 'space-between',
      },
      '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '64px',
        height: '32px',
        margin: '4px auto',
      },
      '& .notifcation-icon': {
        position: 'relative',
      },
      '& .notifcation-icon .number': {
        background: '#F26F62',
        border: '1px solid #FFFFFF',
        width: '20px',
        height: '20px',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        position: 'absolute',
        top: '-12px',
        right: '-10px',
      },
      '& .footerButton': {
        textDecoration: 'none',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.5px',
        color: '#2C150F',
        '&.active .icon': {
          background: '#F26F62',
          borderRadius: '20px',
        },
        '&.active .icon svg * ': {
          fill: '#ffffff',
        },
      },
    },
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  /* --- Header ---- */
  topHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '30px 16px 15px',
    borderRadius: ' 0px 0px 16px 16px',
    flexWrap: 'wrap',
    '& .topRight': {
      display: 'flex',
    },
    '& .fullWeekHeader': {
      display: "flex",
      alignItems: "center",
      marginTop: '3px',
      justifyContent: 'space-around',
    },
    '& .fullWeek': {
      margin: "0 15px",
      display: "flex",
      fontSize: "16px",
      textAlign: "left",
      alignItems: "center",
      lineHeight: "20px",
      letterSpacing: "0px",
    },
    '& .topRight .notifcation-icon': {
      position: 'relative',
    },
    '& .topRight .notifcation-icon .number': {
      background: '#EB4459',
      border: '1px solid #FFFFFF',
      width: '12px',
      height: '12px',
      fontWeight: '500',
      fontSize: '8px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      position: 'absolute',
      top: '0',
      right: '-5px',
    },
    '& .topRight .user-icon': {
      background: '#F26F62',
      width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
    },
    '& .topRight .user-icon a': {
      display: 'flex',
      width: '100%',
      height: '100%',
      padding: '7px',
    },
    '& .btn-calendar.showCalendar': {
      transform: 'rotate(180deg)',
      position: 'relative',
      display: 'inline-block',
    },
    '& .home-calendar .react-calendar': {
      margin: '10px 0 0',
      width: '100%',
    },
    '& .home-calendar .react-calendar__navigation button': {
      fontSize: '20px',
    },
    '& .home-calendar .react-calendar__viewContainer': {
      height: '45px',
      overflow: 'hidden',
    },
    '& .home-calendar.showCalendar .react-calendar__viewContainer': {
      height: '100%',
      overflow: 'visible',
    },


  },
  topPart: {
    width: 'calc(100% - 50px)',
  },
  topP1: {
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '0.5481481552124023px',
    textAlign: 'left',
    margin: '0',
    color: '#201A18',
    fontWeight: 500,
  },
  topP2: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#95929E',
    margin: '4px 0 0',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      display: 'flex',
      margin: '-4px 0 0 8px',
    },
  },

  /* --- Daily List ---- */
  dailyTaps: {
    '& .textHeading': {
      height: '24px',
    },
    '& .dailyTapsCounter': {
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .dailyTapsCounter .tapContainer': {
      width: '80px',
      height: '80px',
      borderRadius: '99px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline-flex',
      color: '#fff',
      fontSize: '36px',
      border: 'none',
      outline: 'none',
      boxShadow: '0px 9px 24px rgba(45, 45, 52, 0.15)',
      position: 'relative',
    },
    '& .tapContainer .tapCounter': {
      position: 'absolute',
    },
    '& .tapContainer img.tapCounter': {
      width: '25px',
      height: '25px',
    },
    '& .tapInfo': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      fontSize: '16px',
    },
    '& .tapInfo .timestamp': {
      fontWeight: '600',
    },
    '& .tapInfo .timestamp.completed': {
      fontWeight: '500',
    },

    /* --- Daily Taps --- */
    '& .dailyHeading ': {
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#201A18',
      margin: '0 0 10px',
    },
    '& .dailytapHedaer ': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#FFEAE8',
      borderRadius: '12px',
      marginBottom: '20px',
      boxShadow: '0px 0px 4px 2px #FDDAD7',
      padding: '18px 15px 14px 20px',
    },
    '& .dailytapHedaer .circular ': {
      width: '70px',
      height: '70px',
    },
    '& .dailytapHedaer .circular .CircularProgressbar-text': {
      fontWeight: '500',
      fontSize: '30px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      transform: 'translateY(3px)',

    },
    '& .dailytapHedaer .dailytapDesc': {
      width: ' calc(100% - 100px)',
      paddingRight: '10px',
    },
    '& .dailytapHedaer .dailytapDesc .title': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#201A18',
      margin: '0',
    },
    '& .dailytapHedaer .dailytapDesc .desc': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#666666',
      margin: '3px 0 0',
    },

    '& .dailyTapsList ': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundColor: '#fff',
      borderRadius: '12px',
      padding: '15px 20px',
      marginBottom: '10px',
    },
    '& .dailyTapsList .listDesc': {
      width: '150px',
    },
    '& .dailyTapsList .moreDetail': {
      marginTop: '5px',
      display: 'flex',
    },
    '& .dailyTapsList .moreDetail .active-btn': {
      background: '#FFEAE8',
      borderRadius: '100px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#F26F62',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      padding: '0px 15px 0 22px',
    },
    '& .dailyTapsList .moreDetail .active-btn::before': {
      position: 'absolute',
      content: ' "" ',
      background: '#F26F62',
      width: '8px',
      height: '8px',
      borderRadius: '100%',
      left: '8px',
    },
    '& .dailyTapsList .moreDetail .unactive-btn': {
      background: '#F2F1F7',
      borderRadius: '100px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#A19FAA',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      padding: '0px 15px 0 22px',
    },
    '& .dailyTapsList .moreDetail .unactive-btn::before': {
      position: 'absolute',
      content: ' "" ',
      background: '#A19FAA',
      width: '8px',
      height: '8px',
      borderRadius: '100%',
      left: '8px',
    },

    '& .dailyTapsList:last-child': {
      marginBottom: '0',
    },

    '& .dailyTapsList .title': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#201A18',
      margin: '0',
    },
    '& .dailyTapsList .desc': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#666666',
      margin: '0',
    },
    '& .dailyTapsList .checkboxList': {
      display: 'flex',
      flexWrap: 'wrap',
      width: 'calc(100% - 150px)',
      justifyContent: 'flex-end',
    },
  },

  card: {
    width: '100%',
    marginTop: '5px',
    '& .pCover': {
      display: 'flex',
      '& .first': {
        width: '40%',
      },
      '& .second': {
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#6B6B6F',
      },
    },
  },

  timeTab: {
    width: '280px',
    height: '36px',
    margin: '25px auto',
    borderRadius: '10px',
    backgroundColor: '#898888',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& span': {
      width: '50%',
      textAlign: 'center',
      height: '36px',
      lineHeight: '36px',
    },
    '& .active': {
      background: 'linear-gradient(140.62deg, #F97C74 -12%, #F86060 100%)',
      borderRadius: '10px',
      boxShadow: '0px 9px 15px rgba(45, 45, 52, 0.15)',
    },
  },

  coverPadding: {
    padding: '20px 16px',
  },
  cover: {
    width: '100%',
    overflow: 'auto',
    height: 'calc(100vh - 90px)',
    backgroundPosition: 'top center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    '& .time': {
      height: '36px',
      width: '280px',
      left: '47px',
      top: '180px',
      borderRadius: '10px',
    },
    '& .timeSlide': {
      textAlign: 'center',
      height: '22px',
      padding: '20px 0',
    },
    '& .timeSlide span': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '22px',
      letterSpacing: '0px',
      textAlign: 'center',
      color: '#424248',
    },
    '& .timeSlide svg': {
      height: '10px',
      width: '12px',
      borderRadius: '0px',
      marginLeft: '5px',
      marginRight: '5px',
    },
    '& .weekdays': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '72px',
      marginTop: '10px',
      position: 'relative',
      padding: '0 10px',
    },
    '& .weekdays .left-icon,.weekdays .right-icon': {
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      webkitTransform: 'translateY(-50%)',
      oTransform: 'translateY(-50%)',
      msTransform: 'translateY(-50%)',
      mozTransform: 'translateY(-50%)',
      cursor: 'pointer',
    },
    '& .weekdays .right-icon': {
      left: 'auto',
      right: '0',
    },
    '& .weekdays .weekdayContainer': {
      height: '30px',
      width: '30px',
      display: 'inline-flex',
      flexDirection: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '99px',
      position: 'relative',
    },

    '& .weekdays .weekdayContainer .weekday.today .CircularProgressbar .CircularProgressbar-background': {
      fill: '#ddd !important',
    },
    '& .weekdays .weekdayContainer .weekday.hundred .CircularProgressbar .CircularProgressbar-background[cx]': {
      fill: 'rgba(242, 111, 98,1) !important',
    },
    '& .weekdays .weekdayContainer .CircularProgressbar,  & .react-calendar .CircularProgressbar': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '40px',
      transform: 'translate(-50%, -50%)',
    },
    '& .weekdays .weekdayContainer .CircularProgressbar': {
      marginTop: '35px',
    },
    '& .react-calendar': {
      background: 'transparent',
      border: 'none',
      fontFamily: 'Roboto',
      marginTop: '10px',
      width: '100%',
    },
    '& .react-calendar .react-calendar__navigation': {
      width: '100%',
      margin: '0',
    },
    '& .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow div': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .react-calendar__navigation .react-calendar__navigation__label': {
      margin: '0 5px',
    },
    '& .react-calendar__tile': {
      padding: 'unset',
      height: '40px',
      width: '50px !important',
      maxWidth: 'unset!important',
      position: 'relative',
      margin: '6px 0',
      background: '#fff',
      color: 'rgb(103, 103, 103)',

    },
    '& .weekdayContainer  .CircularProgressbar .CircularProgressbar-text': {
      fill: "rgb(103, 103, 103)",
      fontSize: '40px !important',
      transform: 'translateY(4px)',
    },
    '& .react-calendar__tile .CircularProgressbar.activeProgress .CircularProgressbar-background': {
      fill: 'rgb(242, 111, 98) !important',

    },


    '& .react-calendar__viewContainer  .CircularProgressbar .CircularProgressbar-text': {
      fill: "rgb(103, 103, 103)",
      fontSize: '40px !important',
      transform: 'translateY(4px)',
    },
    '& .react-calendar__tile:hover .CircularProgressbar .CircularProgressbar-background': {
      fill: '#ddd !important',
    },
    '& .react-calendar__tile--now .CircularProgressbar .CircularProgressbar-background': {
      fill: '#ddd !important',
    },
    '& .react-calendar__tile:focus .CircularProgressbar .CircularProgressbar-background': {
      fill: '#ddd !important',
    },
    '& .react-calendar__tile:hover .CircularProgressbar.activeProgress .CircularProgressbar-background,.react-calendar__tile:focus .CircularProgressbar.activeProgress .CircularProgressbar-background': {
      fill: 'rgb(242, 111, 98) !important',
    },

    '& .react-calendar__century-view .react-calendar__tile': {
      padding: 'unset',
      height: '38px',
      width: '98px!important',
      maxWidth: 'unset!important',
      boxShadow: '0px 2px 7px rgba(45, 45, 52, 0.1)',
      borderRadius: '99px',
      margin: '3.6px 0',
      position: 'relative',
      '& svg ': {
        zIndex: '-1',
      },
    },

    '& .react-calendar__month-view__weekdays__weekday': {
      color: '#95929E',
      fontSize: '12px',
      fontWeight: '500',
    },
    '& .react-calendar__month-view__weekdays__weekday abbr': {
      textDecoration: 'none',
    },

    '& .react-calendar__month-view__days__day--neighboringMonth': {
      visibility: 'hidden',
      opacity: '0',
    },
    '& .react-calendar__month-view__days__day--weekend': {
      color: 'unset',
    },
    '& .react-calendar__year-view__months': {
      '& .react-calendar__tile': {
        padding: 'unset',
        height: '38px',
        width: '98px!important',
        maxWidth: 'unset!important',
        boxShadow: '0px 2px 7px rgba(45, 45, 52, 0.1)',
        borderRadius: '99px',
        margin: '3.6px 0',
        fontSize: '14px',
        position: 'relative',
        '& svg ': {
          zIndex: '-1',
        },
      },
    },
    '& .react-calendar__decade-view__years': {
      '& .react-calendar__tile': {
        padding: 'unset',
        height: '38px',
        width: '98px!important',
        maxWidth: 'unset!important',
        boxShadow: '0px 2px 7px rgba(45, 45, 52, 0.1)',
        borderRadius: '99px',
        margin: '3.6px 0',
        position: 'relative',
        '& svg ': {
          zIndex: '-1',
        },
      },
    },

    '& .react-calendar__tile--active': {
      background: 'transparent',
    },
    '& .react-calendar__tile--hasActive,.react-calendar__tile--hasActive:hover,.react-calendar__tile--hasActive:focus': {
      background: '#F26F62',
      color: '#fff',
    },

    '& .react-calendar__tile--now:enabled:hover,.react-calendar__tile--now:enabled:focus': {
      background: '#F26F62',
    },
    ' & .react-calendar__tile.react-calendar__month-view__days__day:hover,.react-calendar__tile.react-calendar__month-view__days__day:focus': {
      background: 'transparent',
    },

    '& .react-calendar__tile:hover,.react-calendar__tile:focus': {
      background: '#F26F62',
      color: '#fff',
    },
    '& .react-calendar__year-view__months__month.react-calendar__tile--now,.react-calendar__year-view__months__month:hover': {
      background: '#F26F62',
      color: '#fff',
    },

    '& .react-calendar__decade-view__years__year.react-calendar__tile--now,.react-calendar__decade-view__years__year:hover': {
      background: '#F26F62',
      color: '#fff',
    },
    '& .react-calendar__tile--now.react-calendar__century-view__decades__decade,.react-calendar__century-view__decades__decade:hover': {
      background: '#F26F62',
      color: '#fff',
    },
    ' & .react-calendar__navigation button:hover,.react-calendar__navigation button:focus': {
      background: '#F26F62',
      color: '#fff',
    },
    ' & .react-calendar__navigation button:hover svg path,.react-calendar__navigation button:focus svg path': {
      fill: '#fff',
    },
    '& .react-calendar__tile .unExpected::before': {
      position: 'absolute',
      content: ` "" `,
      backgroundColor: 'grey',
      width: '9px',
      height: '9px',
      borderRadius: '100%',
      top: '0',
      right: '0',
    },
    '& .weekdayContainer .unExpected::before': {
      position: 'absolute',
      content: ` "" `,
      backgroundColor: 'grey',
      width: '9px',
      height: '9px',
      borderRadius: '100%',
      bottom: '0',
      right: '0',
    },
  },
  thirdText: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#424248',
  },
  cardHeading: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#424248',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      borderLeft: '1px solid #fde3df',
      padding: '3px 15px',
    },
  },

  header: {
    display: 'inline-flex',
    zIndex: 9,
    position: 'absolute',
  },
  fillimage: {
    left: '-0.27%',
    right: ' 0%',
    top: '20.67%',
    bottom: ' 0%',
    background: 'rgba(255, 236, 197, 0.8)',
  },
  fillimages: {
    top: '0',
    left: '0%',
    right: '0',
    width: '100%',
    bottom: '0%',
    position: 'absolute',
    zIndex: 2,
    objectFit: 'cover',
  },

  banner: {
    flex: 1,
    padding: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    flexDirection: 'column',
  },
  paper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    minWidth: '350px',
    borderRadius: '3%',
    [theme.breakpoints.up('xs')]: {
      minWidth: '250px',
      flex: 3,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '250px',
      flex: 2,
    },
  },
  logoWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
  },

  bannerTextWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: '#fff',
  },
  bannerText1: {
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    lineHeight: '41px',
    fontSize: '26px',
    marginLeft: '19px',
    letterSpacing: '0.548148px',
    color: '#4D4D5A',
  },


  medication: {
    display: 'flex',
    gap: '5%',
    'flex-direction': 'row',
  },

  medications: {
    boxShadow: '0px 10px 15px -5px rgba(45, 45, 52, 0.15)',
    marginTop: '10%',

    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '5px',
      borderBottom: '1px solid #F3F1EE',
      padding: '10px 0',
    },
    '& .leftHeaderPortion, & .rightHeaderPortion': {
      display: 'flex',
      fontSize: '1.5em',
      alignItems: 'center',
      fontWeight: '600',
    },
    '& .leftHeaderPortion .icon': {
      margin: '0 10px',
    },
    '& .rightHeaderPortion .rightPortionElement': {
      height: '27px',
      width: '44px',
      borderRight: '0.5px solid #F97C74',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .rightHeaderPortion .rightPortionElement:last-child': {
      borderRight: 'none',
    },
    '& .rightHeaderPortion .rightPortionElement .checkmarkContainer': {
      backgroundColor: '#F97C74',
      width: '18.86px',
      height: '18.86px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '99px',
    },
    '& .medicationList': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .medicationList .medication': {
      display: 'flex',
      margin: '10px 0 0 10px',
    },
    '& .medicationList .medication:last-child': {
      marginBottom: '15px',
    },
    '& .medication .checkmarkIcon': {
      marginRight: '10px',
    },
    '& .medication .medicationName': {
      width: '25%',
    },
    '& .medication .medicationDosage': {
      color: '#6B6B6F',
    },
  },
  partialProgress: {
    backgroundColor: 'transparent',
    pathColor: 'red',
    trailColor: 'transparent',
  },
  fullProgress: {
    backgroundColor: '#FF9765',
    pathColor: 'white',
    trailColor: 'transparent',
  },


}));
