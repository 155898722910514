import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SurveyPageStyle } from './styles';
import { createTap } from 'services/api/tap';
import logoIcon from '../../SuccessPage/Assets/logo-icon.png';

export function SetSuccessPage() {
  const classes = SurveyPageStyle();
  const history = useHistory();
  const location = useLocation();

  const tapLabel = async labelId => {
    try {
      await createTap(labelId);
    } catch (err) {
      return
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const labelId = searchParams.get('label_id');

    if (labelId) {
      tapLabel(labelId);
      searchParams.delete('label_id');
      history.replace(location.pathname);
    }
  }, [history, location]);

  return (
    <div className={classes.root}>
      <div className={classes.login}>
        <div className='topWrap'>
          <h2>Congrats!
            <p>Your account is now active</p>
          </h2>

          <span className='icon'><img
            src={logoIcon}
            alt="welcome icon"
          ></img></span>
        </div>
        <div className='bottomWrap'>
          <div className='loginDetail'>
            <div className='loginBtn'>
              <button className='btn-login active' onClick={() => history.push('/login')}>Go to your Tappt account</button>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}

export default SetSuccessPage;
