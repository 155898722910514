import Calendar from 'react-calendar';
import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  setMonthCalendarOffset: (offset: any) => void;
  tileContent: (data: any) => JSX.Element;
}

const MonthlyCalendar = ({ setMonthCalendarOffset, tileContent }: Props) => {

  return (
    <Typography component="div" className="monthdays">
      <Calendar
        next2Label={null}
        prev2Label={null}
        prevLabel={
          <div onClick={() => setMonthCalendarOffset(prev => prev - 1)}>
            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-1.51753e-05 7.12708L6.99998 0.127075L8.25415 1.38124L2.47915 7.12708L8.22498 12.8729L6.97082 14.1271L-1.51753e-05 7.12708Z" fill="#201A18" />
            </svg>
          </div>
        }
        nextLabel={
          <div onClick={() => setMonthCalendarOffset(prev => prev + 1)}>
            <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.12709 7.12707L2.12709 14.1271L0.872925 12.8729L6.64792 7.12707L0.90209 1.38124L2.15626 0.127075L9.12709 7.12707Z" fill="#201A18" />
            </svg>
          </div>
        }
        formatDay={() => <></>}
        tileContent={tileContent}
      />
    </Typography>
  );
};

export default MonthlyCalendar;
