import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import ProgressBar from "../ProgressBar";
import moment from "moment";
import { createISOWeekArray } from "services/helpers/date";

const NextSvg = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    onClick={onClick}
  >
    <path
      d="M6 13L1 7L6 0.999999"
      stroke="#2D2D34"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const PrevSvg = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    onClick={onClick}
  >
    <path
      d="M1 1L6 7L1 13"
      stroke="#2D2D34"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
interface DayHistory {
  date: Date;
  needed: number;
  tapped: number;
  unExpected?: boolean | undefined;
  timePeriod?: number;
  id?: number;
}
interface Props {
  setCalendarOffset: (offset: any) => void;
  history: DayHistory[];
  offset?: number;
}
const getWeekSpanString = (dates: Date[]) => {
  const startDate = moment(dates[0]);
  const endDate = moment(dates[dates.length - 1]);

  if (startDate.month() !== endDate.month()) {
    return `${startDate.format("MMMM D")}-${endDate.format("MMMM D")}`;
  }
  return `${startDate.format("MMMM D")}-${endDate.format("D")}`;
};

const WeeklyCalendar = ({ setCalendarOffset, history, offset = 0 }: Props) => {
  const [tapHistory, setTapHistory] = useState<DayHistory[]>([]);
  useEffect(() => {
    const week = createISOWeekArray(offset);
    setTapHistory(
      week.map((date) => {
        let dataObj: any = {};

        const tapFilter: any =
          history?.length &&
          history?.filter(
            (val) =>
              moment(date).isSame(val?.date, "day") && val?.timePeriod === 1
          );

        let resObject = {};

        if (tapFilter?.length) {
          resObject = {
            date: tapFilter?.[0]?.date,
            needed: 0,
            tapped: 0,
            unExpected: false,
          };
          let neededTotal = 0,
            tappedTotal = 0,
            isUnExpected = false;

          tapFilter?.map((item) => {
            neededTotal += item?.needed;
            tappedTotal += item?.tapped;
            if (item?.unExpected) isUnExpected = true;
          });

          resObject["needed"] = neededTotal;
          resObject["tapped"] = tappedTotal;
          resObject["unExpected"] = isUnExpected;
          dataObj = resObject;
        } else {
          dataObj = history?.find(
            ({ date: tapDate, timePeriod: timePeriod }) =>
              moment(tapDate).isSame(moment(date), "day") && timePeriod != 1
          );
        }
        // const { needed = 0, tapped = 0, unExpected = false } = {
        //   ...history.find(({ date: hDate }) =>
        //     moment(date).isSame(hDate, "day")
        //   ),
        // };
        return {
          date,
          needed: dataObj?.needed,
          tapped: dataObj?.tapped,
          unExpected: dataObj?.unExpected,
          timePeriod: dataObj?.timePeriod,
        };
      })
    );
  }, [history, offset]);
  return (
    <>
      <Typography component="div" className="weekdays">
        {tapHistory &&
          tapHistory.map(({ needed, tapped, date, unExpected }, i) => {
            const progress = needed ? (tapped / needed) * 100 : 0;
            return (
              <div
                className="weekdayContainer "
                key={i}
                style={{
                  color: moment(date).isSame(moment(), "day")
                    ? "#F26F62"
                    : "#676767",
                }}
              >
                <div
                  className={`weekday ${
                    moment(date).isSame(moment(), "day") && progress == 100
                      ? "hundred"
                      : moment(date).isSame(moment(), "day") && progress < 100
                      ? "today"
                      : ""
                  }`}
                >
                  <div className="dayName">{weekDays[i]}</div>
                  <ProgressBar
                    unExpected={unExpected}
                    needed={needed}
                    progress={progress}
                    label={moment(date).format("D")}
                  />
                </div>
              </div>
            );
          })}
      </Typography>
      <div className="fullWeekHeader">
        <span className="left-icon">
          <NextSvg onClick={() => setCalendarOffset((prev) => prev - 1)} />
        </span>
        <span className="fullWeek">
          Week of{" "}
          {tapHistory &&
            tapHistory.length &&
            getWeekSpanString(tapHistory.map((v) => v.date))}
        </span>
        <span className="right-icon">
          <PrevSvg onClick={() => setCalendarOffset((prev) => prev + 1)} />
        </span>
      </div>
    </>
  );
};

export default WeeklyCalendar;
