import FooterPageHtml from 'app/containers/footer/UserListView/htm';
import React, { useState } from 'react';
import { CheckInsPageStyle } from './styles';
import CheckInsHeader from './components/CheckInsHeader';
import CheckInsTab from './components/CheckInsTab';


export function CheckInsPageHtml() {

  const classes = CheckInsPageStyle();
  const [activeTab, setTab] = useState<boolean>(false)

  return (
    <div className={classes.root}>
      <div className={classes.cover}>
        <CheckInsHeader activeTab={activeTab} setTab={setTab} />
        <div className={classes.coverPadding}>
          <CheckInsTab activeTab={activeTab} />
        </div>
      </div>
      <div className="footerDiv">
        <FooterPageHtml />
      </div>
    </div>
  );
}

export default CheckInsPageHtml;
