import { makeStyles } from '@material-ui/core/styles';

import Path from '../../Auth/Assets/Group 6.png';

export const SurveyPageStyle = makeStyles(theme => ({
  root: {
    background: 'white',
    height: '100vh',
    '& .footerDiv': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '9%',
      background: '#fff',
    },
  },
  card: {
    padding: '0px 10px 0px 10px',
    width: '100%',
    marginBottom: '20px',
    paddingBottom: '10px',
    borderRadius: '10px',
    backgroundColor: '#FEFCFA',
    boxShadow: '0px 9px 24px 0px #2D2D3426',
    '& .pCover': {
      display: 'flex',
      '& .first': {
        width: '40%',
      },
      '& .second': {
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#6B6B6F',
      },
    },
  },

  timeTab: {
    width: '280px',
    height: '36px',
    margin: '25px auto',
    borderRadius: '10px',
    backgroundColor: '#898888',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& span': {
      width: '50%',
      textAlign: 'center',
      height: '36px',
      lineHeight: '36px',
    },
    '& .active': {
      background: 'linear-gradient(140.62deg, #F97C74 -12%, #F86060 100%)',
      borderRadius: '10px',
      boxShadow: '0px 9px 15px rgba(45, 45, 52, 0.15)',
    },
  },

  cover: {
    width: '100%',
    padding: '15px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '90%',
    backgroundPosition: 'top center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('.${Path}')`,
    backgroundColor: '#FFFFFF',
    '& .time': {
      height: '36px',
      width: '280px',
      left: '47px',
      top: '180px',
      borderRadius: '10px',
    },
    '& .timeSlide': {
      textAlign: 'center',
      height: '22px',
      padding: '20px 0',
    },
    '& .timeSlide span': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '22px',
      letterSpacing: '0px',
      textAlign: 'center',
      color: '#424248',
    },
    '& .timeSlide svg': {
      height: '10px',
      width: '12px',
      borderRadius: '0px',
      marginLeft: '5px',
      marginRight: '5px',
    },
    '& .weekdays': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '59px',
    },
    '& .weekdays .weekdayContainer': {
      boxShadow: '0px 2px 7px rgba(45, 45, 52, 0.1)',
      height: '38px',
      width: '38px',
      display: 'inline-flex',
      flexDirection: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '99px',
    },
  },

  topPart: {},
  topP1: {
    fontSize: '37px',
    lineHeight: '41px',
    letterSpacing: '0.5481481552124023px',
    textAlign: 'left',
    marginBottom: '0px',
  },
  topP2: {
    width: '327px',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '26px',
    lineHeight: '41px',
    /* identical to box height, or 158% */

    textAlign: 'center',
    letterSpacing: ' 0.548148px',

    color: '#4D4D5A',
  },
  thirdText: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#424248',
  },
  cardHeading: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#424248',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      borderLeft: '1px solid #fde3df',
      padding: '3px 15px',
    },
  },

  header: {
    display: 'inline-flex',
    zIndex: 9,
    position: 'absolute',
  },
  fillimage: {
    left: '-0.27%',
    right: ' 0%',
    top: '20.67%',
    bottom: ' 0%',
    background: 'rgba(255, 236, 197, 0.8)',
  },
  fillimages: {
    top: '0',
    left: '0%',
    right: '0',
    width: '100%',
    bottom: '0%',
    position: 'absolute',
    zIndex: 0,
    pointerEvents: 'none',
    objectFit: 'cover',
  },

  banner: {
    flex: 1,
    padding: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    flexDirection: 'column',
  },
  paper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    minWidth: '350px',
    borderRadius: '3%',
    [theme.breakpoints.up('xs')]: {
      minWidth: '250px',
      flex: 3,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '250px',
      flex: 2,
    },
  },
  logoWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
  },

  bannerTextWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: '#fff',
  },
  bannerText1: {
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    lineHeight: '41px',
    fontSize: '26px',
    marginLeft: '19px',
    letterSpacing: '0.548148px',
    color: '#4D4D5A',
  },

  dailyTaps: {
    '& .textHeading': {
      height: '24px',
    },
    '& .dailyTapsCounter': {
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .dailyTapsCounter .tapContainer': {
      width: '80px',
      height: '80px',
      background: 'linear-gradient(131.41deg, #FF9765 -7.11%, #F86060 100%)',
      borderRadius: '99px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline-flex',
      color: '#fff',
      fontSize: '36px',
    },
    '& .tapInfo': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      fontSize: '16px',
    },
    '& .tapInfo .timestamp': {
      fontWeight: '600',
    },
  },
  OptionCardView: {
    left: '15px',
    // position:'relative',
    right: '15px',
    top: '0px',
    bottom: '310px',

    background: '#FFFFFF',
    boxshadow: '0px 9px 24px rgba(45, 45, 52, 0.15)',
    borderradius: '10px',
  },
  IssueWarningMessage: {
    // position: 'absolute',
    height: '50px',
    left: ' 9.33%',
    right: '13.6%',
    top: 'calc(50% - 50px/2 - 303px)',
    //marginTop:'35%',
    marginLeft: '5%',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '21px',
    lineHeight: '25px',

    color: '#424248',
  },
  selectOption: {
    // position: 'absolute',
    left: ' 17.87%',
    right: '8.8%',
    top: '46.26%',
    bottom: '47.41%',

    border: '1px solid #E8E6E4',
    borderRadius: '2px',
  },
  checkbox: {
    // position: 'absolute',
    //width: '13.98px',
    //height: '13.98px',
    right: '14.02px',
    top: 'calc(50% - 13.98px/2 - 0.01px)',
    padding: '5px',
    background: '#FEFCFA',
    //border:' 1px solid #B4B3B3',
  },
  confirmButtonContainer: {
    position: 'fixed',
    bottom: '5%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '50%',
    height: '50px',
  },
  ConfirmButton: {
    color: 'white',
    width: '100%',
    padding: '10px',
    border: 'none',
    height: ' 100%',
    background: 'linear-gradient(140.62deg, #F97C74 -12%, #F86060 100%)',
    boxShadow: ' 0px 9px 15px rgb(45 45 52 / 15%)',
    marginTop: '15px',
    borderRadius: '10px',
  },
  cover23: {
    background: '#FFFFFF',
    padding: '10px',
    boxShadow: ' 0px 9px 24px rgba(45, 45, 52, 0.15)',
    borderRadius: '10px',
  },
  Confirmtext: {
    height: '21px',
    left: '8.53%',
    right: '8.53%',
    top: 'calc(50% - 21px/2 - 15.5px)',
    color: 'white',
    fontFamily: ' Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '21px',
    lineHeight: '21px',
    textAlign: 'center',
  },
  outercircle: {
    background: 'linear-gradient(135.83deg, #FF9765 -20.48%, #F86060 100%)',
    boxShadow: ' 0px 9px 24px rgba(45, 45, 52, 0.15)',
  },
  FinalText: {
    width: '100%',
    left: '48px',
    fontFamily: ' Roboto',
    fontStyle: 'normal',
    fontWeight: 200,
    fontSize: '20px',
    textAlign: 'center',
    color: ' #6B6B6F',
  },
  innercircle: {
    '@media (max-height:450px)': {
      margin: '10px auto 10px',
    },
    margin: '100px auto 10px',
    display: 'block',
    height: '209px',
    width: '209px',
  },
  medications: {
    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .leftHeaderPortion, & .rightHeaderPortion': {
      display: 'flex',
      fontSize: '1.5em',
      alignItems: 'center',
    },
    '& .leftHeaderPortion span': {
      fontWeight: '600',
      marginLeft: '10px',
    },
  },
}));
