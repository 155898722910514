import * as React from 'react';
import { Route } from 'react-router-dom';
import TapsPageHtml from './TapsView/htm';

export function TapsPage({ match }) {
  return (
    <>
      <Route exact path={match.url} component={TapsPageHtml} />
    </>
  );
}
