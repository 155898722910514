import { createGlobalStyle } from 'styled-components';

/* istanbul ignore next */

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto-Black.eot');
    src: url('fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto-Black.woff2') format('woff2'),
        url('fonts/Roboto-Black.woff') format('woff'),
        url('fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
      font-family: 'Roboto';
      src: url('fonts/Roboto-Bold.eot');
      src: url('fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
          url('fonts/Roboto-Bold.woff2') format('woff2'),
          url('fonts/Roboto-Bold.woff') format('woff'),
          url('fonts/Roboto-Bold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Roboto';
      src: url('fonts/Roboto-Light.eot');
      src: url('fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
          url('fonts/Roboto-Light.woff2') format('woff2'),
          url('fonts/Roboto-Light.woff') format('woff'),
          url('fonts/Roboto-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Roboto';
      src: url('fonts/Roboto-Medium.eot');
      src: url('Roboto-Medium.eot?#iefix') format('embedded-opentype'),
          url('fonts/Roboto-Medium.woff2') format('woff2'),
          url('fonts/Roboto-Medium.woff') format('woff'),
          url('fonts/Roboto-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Roboto';
      src: url('fonts/Roboto-Regular.eot');
      src: url('fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/Roboto-Regular.woff2') format('woff2'),
          url('fonts/Roboto-Regular.woff') format('woff'),
          url('fonts/Roboto-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }


  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family:'Roboto' !important;
    background: '#F2F1F7' !important;
  }

  body.fontLoaded {
    font-family:'Roboto' !important;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

   img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
   }


   ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #818181;
  background-clip: padding-box;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #818181;
}
/* Input Focus */
form input:focus-visible{
  outline: 2px solid #FC8263;
}
/* Input Focus */
form input:focus{
  outline: 2px solid #FC8263;
}

.MuiGrid-root input {
  
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 4px;

  /* Firefox 1-3.6 */
  -moz-border-radius: 4px;

  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 4px;
}

`;

