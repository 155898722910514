import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSmStylesOtp } from './styles';
import logoText from '../../Assets/logo-text.svg';
import logoIcon from '../../Assets/logo-icon.png';

export function ComponentPage() {
  const classesSm = useSmStylesOtp();
  const history = useHistory();

  return (
    <div className={classesSm.root}>
      <div className={classesSm.login}>
        <div className='topWrap'>
          <h3>Welcome to</h3>
          <h2>
            <img
              src={logoText}
              alt="welcome icon"
            ></img>
          </h2>
          <span className='icon'><img
            src={logoIcon}
            alt="welcome icon"
          ></img></span>
        </div>
        <div className='bottomWrap'>
          <div className='loginDetail'>
            <div className='loginBtn'>
              <button className='btn-login active' onClick={() => history.push('/set-password')}>Activate</button>
            </div>
            <div className='copyRight'>
              <span><Link to="/login" >Terms of service </Link>&<Link to="/login"> privacy policy </Link></span>
              <p>&copy; Powered by <Link to="/login" target="_blank">Synchronyx</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComponentPage;
