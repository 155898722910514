/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { GlobalStyle } from '../styles/global-styles';
import LandinPage from './containers/Auth/LandingPage/ComponentPage';
import { LoginPage } from './containers/Auth/LoginPage';
import { Finishpage } from './containers/FinishPage';
import { Finishpage as SuccessPage } from './containers/SuccessPage';
import { MedicationPage } from './containers/Medication/loadable';
import { ResourcesPage } from './containers/Resources/loadable';
import { TapsPage } from './containers/Taps';
import { NotFoundPage } from './containers/NotFoundPage/Loadable';
import { ProfilePage } from './containers/profile/loadable';
import { SetPasswordPage } from './containers/Auth/SetPassword';
import { ForgotPasswordPage } from './containers/Auth/ForgotPassword';
import { ErrorPage } from './containers/ErrorPage';
import PrivateRoute from 'utils/PrivateRoute';
import { CheckInsDataPage } from './containers/CheckIns';
import Question from './containers/CheckIns/UserListView/components/Question';
import ReportError from './containers/profile/UserListView/components/ReportError';
import { WelcomePage } from './containers/Auth/Welcome';
import SetSuccessPage from './containers/SetSuccessPage/UserListView/htm';
import { ChangeFinishPage } from './containers/Auth/ChangeSuccessPage';

const TRACKING_ID = 'G-YH154FTP3J'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export function App() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    // @ts-ignore
    <BrowserRouter>
      {
        // @ts-ignore
        <Helmet titleTemplate="%s - Synchronyx" defaultTitle="Synchronyx">
          <meta name="description" content="A Synchronyx application" />
        </Helmet>
      }
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/landing" component={LandinPage} />
        <Route path="/forgot_password" component={ForgotPasswordPage} />
        <Route path="/change-success" component={ChangeFinishPage} exact />
        <Route path="/set-success" component={SetSuccessPage} exact />
        <Route exact path="/set-password/:token" component={SetPasswordPage} />
        <Route path="/success" component={SuccessPage} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/welcome" component={WelcomePage} />
        <PrivateRoute path="/taps" component={TapsPage} exact />
        <PrivateRoute path="/finish" component={Finishpage} exact />
        <PrivateRoute path="/profile" component={ProfilePage} exact />
        <PrivateRoute path="/taps" component={TapsPage} exact />
        <PrivateRoute path="/myMedication" component={MedicationPage} exact />
        <PrivateRoute path="/resources" component={ResourcesPage} exact />
        <PrivateRoute path="/check-ins" component={CheckInsDataPage} exact />
        <PrivateRoute path="/question/:id" component={Question} exact />
        <PrivateRoute path="/report-error" component={ReportError} exact />
        <Route path="/*" component={TapsPage} />
        <Route path="/no-access" component={NotFoundPage} />
        <Route path="/*" component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
