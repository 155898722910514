import { makeStyles } from '@material-ui/core/styles';


export const CheckInsPageStyle = makeStyles(theme => ({
  root: {
    fontFamily: 'Roboto',
    height: '100vh',
    '& .footerDiv': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '90px',
      zIndex:'9999',
      padding: '12px 15px',
      maxWidth: '412px',
      textAlign: 'center',
      margin: '0px auto',
      background: '#F8F6F7',
      '& .buttons': {
        justifyContent: 'space-between',
      },
      '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '64px',
        height: '32px',
        margin: '4px auto',
      },
      '& .notifcation-icon': {
        position: 'relative',
      },
      '& .notifcation-icon .number': {
        background: '#F26F62',
        border: '1px solid #FFFFFF',
        width: '20px',
        height: '20px',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        position: 'absolute',
        top: '-12px',
        right: '-10px',
      },
      '& .footerButton': {
        textDecoration: 'none',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.5px',
        color: '#2C150F',
        '&.active .icon': {
          background: '#F26F62',
          borderRadius: '20px',
        },
        '&.active .icon svg * ': {
          fill: '#ffffff',
        },
      },
    },
    '& .subFooter': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '85px',
      padding: '12px 16px',
      maxWidth: '412px',
      textAlign: 'center',
      margin: '0px auto',
      display: 'flex',
      alignItems: 'center',
      '& .btnList': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        listStyleType: 'none',
        margin: '0',
        padding: '0',
        width: '100%',
      },
      '& .btnList.flex-end': {
        justifyContent: 'flex-end',
      },
      '& .btnList button ': {
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '28px',
        color: '#201A18',
        margin: '0',
        padding: '0',
        background: 'none',
        border: 'none',
      },
      '& .btnList .confrim': {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        background: '#F26F62',
        borderRadius: '100px',
        width: '100%',
        padding: '18px 10px',
      },

      '& .btnList img': {
        marginLeft: '10px',
      },
      '& .btnList .back': {
        margin: '0 10px 0 0',
      },
    },


  },

  cover: {
    width: '100%',
    overflow: 'auto',
    backgroundPosition: 'top center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    height: 'calc(100vh - 90px)',
  },
  coverPadding: {
    padding: '20px 16px',
  },


  /* --- Header --- */
  topHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '30px 16px 0',
    borderRadius: ' 0px 0px 16px 16px',
    '& .topRight': {
      display: 'flex',
    },
    '& .topRight .notifcation-icon': {
      position: 'relative',
    },
    '& .topRight .notifcation-icon .number': {
      background: '#EB4459',
      border: '1px solid #FFFFFF',
      width: '12px',
      height: '12px',
      fontWeight: '500',
      fontSize: '8px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      position: 'absolute',
      top: '0',
      right: '-5px',
    },
    '& .topRight .user-icon': {
      background: '#F26F62',
      width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      marginLeft: '15px',
    },
    '& .topRight .user-icon a': {
      display: 'flex',
      width: '100%',
      height: '100%',
      padding: '7px',
    },
  },

  topPart: {
    width: 'calc(100% - 90px)',
  },
  topP1: {
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '0.5481481552124023px',
    textAlign: 'left',
    margin: '0',
    color: '#201A18',
    fontWeight: 500,
  },

  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 16px',
    flexWrap: 'wrap',
    '& .question-number': {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '28px',
      color: '#201A18',
      minWidth: '50px',
      textAlign: 'right',
    },
    '& .progress': {
      width: '100%',
      marginTop: '18px',
    },
    '& .progress .RSPBprogressBar': {
      backgroundColor: '#ffffff',
    },
  },
  topP2: {
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'left',
    color: '#201A18',
    fontWeight: 500,
    margin: '0',
    '& img ': {
      marginRight: '13px',
    },
    '& a': {
      textDecoration: 'none',
      color: '#201A18',
    },
  },



  MedicationTab: {
    width: '100%',
    marginTop: '20px',
    '& .tab-list': {
      display: 'flex',
      padding: '0',
      margin: '0',
      listStyle: 'none',
      '& li': {
        width: '50%',
        fontWeight: '500',
        fontSize: '16px',
        color: '#53433F',
        cursor: 'pointer',
        textAlign: 'center',
        position: 'relative',
        paddingBottom: '10px',
        borderBottom: '3px solid transparent',
      },
      '& li.active': {
        fontWeight: '600',
        color: '#F26F62',
        borderBottom: '3px solid #F26F62',
      },
    },
  },
  MedicationDetail: {
    '& .tab-detail': {
      display: 'none',
    },
    '& .completedList .checkList .icon svg *': {
      fill: '#BFBDC9',
    },
    '& .completedList .checkList .checklistsDesc .title': {
      color: '#666666',
    },
    '& .tab-detail.active': {
      display: 'block',
    },
    '& .tab-detail .openList': {
      display: 'flex',
      alignItems: 'center',
      listStyleType: 'none',
      padding: '0',
      margin: '0 0 16px',
    },
    '& .tab-detail .openList li': {
      background: ' #FFEAE8',
      boxShadow: '0px 0px 4px 2px #FDDAD7',
      borderRadius: '12px',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: ' #201A18',
      padding: '32px 13px 15px',
      flex: '0 0 48.5%',
      width: '48.5%',
      height: '100%',
      marginRight: '12px',
    },
    '& .tab-detail .openList li span': {
      fontWeight: '700',
      fontSize: '32px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: ' #F26F62',
      display: 'block',
      marginBottom: '5px',
    },
    '& .tab-detail .openList li:last-child': {
      marginRight: '0',
    },
    '& .checkList ': {
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      marginBottom: '10px',
      padding: '15px 20px',
    },
    '& .checkList .wrap': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',

    },
    '& .checkList:last-child ': {
      margin: '0',
    },
    '& .checkList .icon ': {
      background: '#F8F6F7',
      borderRadius: '10px',
      width: '45px',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .checkList .checklistsDesc': {
      width: ' calc(100% - 45px)',
      paddingLeft: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .checkList .checklistsDesc .title': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#201A18',
      margin: '0',
    },
    '& .checkList .checklistsDesc .desc': {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#95929E',
      margin: '3px 0 0',
    },

    '& .checkList .checklistsDesc .desc span': {
      fontWeight: '500',
      fontSize: '12px',
      color: '#666666',
    },
    '& .checkList .checklistsDesc .more-btn': {
      background: 'none',
      padding: '0',
      border: 'none',
      boxShadow: 'none',
      marginLeft: '10px',
    },
  },

  questionList: {
    background: '#FFFFFF',
    border: '1px solid #FBDFDD',
    boxShadow: ' 0px 0px 4px 2px #FAE4E2',
    borderRadius: '12px',
    padding: '40px 20px',
    '& .note': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#666666',
      margin: '0',
    },
    '& .question': {
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '0.1px',
      color: '#201A18',
      margin: '10px 0 30px',
    },
    '& .option': {
      padding: '0',
      margin: '0',
      listStyleType: 'none',
    },
    '& .option li': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#201A18',
      backgroundColor: '#F2F1F7',
      boxShadow: '0px 0px 4px 1px #E5E5E6',
      borderRadius: '12px',
      padding: '20px',
      marginBottom: '20px',
    },
    '& .option li:last-child': {
      marginBottom: '0',
    },
    '& .option li.openActive': {
      backgroundColor: '#F26F62',
      color: '#ffffff',
    },
    '& .option li.completedActive': {
      backgroundColor: '#AFAEBB',
      color: '#ffffff',
    },
  },


  modal: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0 auto',
    maxWidth: '412px',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    textAlign: 'center',
    padding: ' 0 50px',
    display: 'none',


    '& .overlay': {
      position: 'absolute',
      content: ' "" ',
      backgroundColor: 'rgba(0,0,0,.6)',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: -1,
    },
    '& .modalContent': {
      background: 'linear-gradient(0deg, #F2F1F7, #F2F1F7), #FFFBFF',
      borderRadius: '28px',
      padding: '27px 24px 34px',
    },
  },
  active: {
    display: 'flex',
  },

  questionModal: {
    '& .desc': {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#201A18',
      margin: '18px 0 38px',
    },
    '& .close-btn': {
      textAlign: 'right',
    },
    '& .close-btn button': {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#F26F62',
      background: 'none',
      border: 'none',
      padding: '0',
    },

  },


}));
