import { IAssignedSurvey } from 'entities/Survey';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getAllAssignedSurveys } from 'services/api/assignedSurvey';
import { MedicationPageStyle } from './styles';

export function FooterPageHtml() {
  const classes = MedicationPageStyle();
  const [assignedsurveys, setAssignedSurveys] = useState<IAssignedSurvey[]>([]);
  const [openArray, setOpenArray] = useState<IAssignedSurvey[]>([])

  useEffect(() => {
    populateSurveys();
  }, []);

  useEffect(() => {
    if (assignedsurveys?.length) {
      const openArr = assignedsurveys?.filter((i) => {
        return !i?.isClosed
      })
      setOpenArray(openArr)
    }

  }, [assignedsurveys])

  const populateSurveys = async () => {
    try {
      setAssignedSurveys(await getAllAssignedSurveys());
    } catch (err) {
    }
  };

  return (
    <div className={classes.root}>
      <div className="buttons">
        <NavLink to={'/taps'} className="footerButton" activeClassName="active">
          <span className='icon'>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.62278 16.3275V11.3275H11.6228V16.3275C11.6228 16.8775 12.0728 17.3275 12.6228 17.3275H15.6228C16.1728 17.3275 16.6228 16.8775 16.6228 16.3275V9.32749H18.3228C18.7828 9.32749 19.0028 8.75749 18.6528 8.45749L10.2928 0.927485C9.91278 0.587485 9.33278 0.587485 8.95278 0.927485L0.592779 8.45749C0.252779 8.75749 0.462779 9.32749 0.922779 9.32749H2.62278V16.3275C2.62278 16.8775 3.07278 17.3275 3.62278 17.3275H6.62278C7.17278 17.3275 7.62278 16.8775 7.62278 16.3275Z" fill="#53433F" />
            </svg>
          </span>
          <span>Home</span>
        </NavLink>
        <NavLink to={'/myMedication'} className="footerButton" activeClassName="active">
          <span className='icon'>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.875 18V16.1L2.375 14.6V18H0.875ZM5 18V12.1L6.5 10.6V18H5ZM9.125 18V10.6L10.625 12.125V18H9.125ZM13.25 18V12.125L14.75 10.625V18H13.25ZM17.375 18V8.1L18.875 6.6V18H17.375ZM0.875 12.1V9.975L7.875 3.025L11.875 7.025L18.875 0V2.125L11.875 9.15L7.875 5.15L0.875 12.1Z" fill="#454545" />
            </svg>
          </span>
          <span>My Medications</span>
        </NavLink>
        <NavLink to={'/check-ins'} className="footerButton" activeClassName="active">
          <span className='icon'>
            <span className='notifcation-icon'>
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.17749 14.175H11.0725C11.2675 14.175 11.4287 14.1112 11.5562 13.9838C11.6837 13.8563 11.7475 13.695 11.7475 13.5C11.7475 13.305 11.6837 13.1437 11.5562 13.0162C11.4287 12.8888 11.2675 12.825 11.0725 12.825H5.17749C4.98249 12.825 4.82124 12.8888 4.69374 13.0162C4.56624 13.1437 4.50249 13.305 4.50249 13.5C4.50249 13.695 4.56624 13.8563 4.69374 13.9838C4.82124 14.1112 4.98249 14.175 5.17749 14.175ZM5.17749 10.35H11.0725C11.2675 10.35 11.4287 10.2863 11.5562 10.1588C11.6837 10.0313 11.7475 9.87 11.7475 9.675C11.7475 9.48 11.6837 9.31875 11.5562 9.19125C11.4287 9.06375 11.2675 9 11.0725 9H5.17749C4.98249 9 4.82124 9.06375 4.69374 9.19125C4.56624 9.31875 4.50249 9.48 4.50249 9.675C4.50249 9.87 4.56624 10.0313 4.69374 10.1588C4.82124 10.2863 4.98249 10.35 5.17749 10.35ZM2.27499 18C1.91499 18 1.59999 17.865 1.32999 17.595C1.05999 17.325 0.924988 17.01 0.924988 16.65V1.35C0.924988 0.99 1.05999 0.675 1.32999 0.405C1.59999 0.135 1.91499 0 2.27499 0H9.83499C10.015 0 10.1912 0.0375 10.3637 0.1125C10.5362 0.1875 10.6825 0.285 10.8025 0.405L14.92 4.5225C15.04 4.6425 15.1375 4.78875 15.2125 4.96125C15.2875 5.13375 15.325 5.31 15.325 5.49V16.65C15.325 17.01 15.19 17.325 14.92 17.595C14.65 17.865 14.335 18 13.975 18H2.27499ZM9.72249 4.86C9.72249 5.055 9.78624 5.21625 9.91374 5.34375C10.0412 5.47125 10.2025 5.535 10.3975 5.535H13.975L9.72249 1.35V4.86Z" fill="#53433F" />
              </svg>
              {
                openArray?.length === 0 ?
                  <span className='number'>
                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M9.54746 0.0966468L4.04754 5.59657L1.69038 3.23941L0.904785 4.02537L3.26195 6.38216L4.04754 7.16776L4.83314 6.38216L10.3331 0.882244L9.54746 0.0966468Z" fill="#FEFCFA" />
                    </svg>
                  </span>
                  :
                  <span className='number'>{openArray?.length}</span>
              }
            </span>
          </span>
          <span>Check-Ins</span>
        </NavLink>
        <NavLink to={'/resources'} className="footerButton" activeClassName="active">
          <span className='icon'>
            <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.93 10.2825L12.8675 7.7175C13.0775 7.5825 13.1825 7.395 13.1825 7.155C13.1825 6.915 13.0775 6.7275 12.8675 6.5925L8.93 4.0275C8.72 3.8775 8.495 3.86625 8.255 3.99375C8.015 4.12125 7.895 4.32 7.895 4.59V9.72C7.895 9.99 8.015 10.1887 8.255 10.3162C8.495 10.4437 8.72 10.4325 8.93 10.2825ZM2.225 14.4C1.865 14.4 1.55 14.265 1.28 13.995C1.01 13.725 0.875 13.41 0.875 13.05V1.35C0.875 0.99 1.01 0.675 1.28 0.405C1.55 0.135 1.865 0 2.225 0H17.525C17.9 0 18.2188 0.135 18.4812 0.405C18.7437 0.675 18.875 0.99 18.875 1.35V13.05C18.875 13.41 18.7437 13.725 18.4812 13.995C18.2188 14.265 17.9 14.4 17.525 14.4H2.225Z" fill="#53433F" />
            </svg>
          </span>
          <span>Resources</span>
        </NavLink>
      </div>
    </div>
  );
}

export default FooterPageHtml;

