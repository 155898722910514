import { makeStyles } from '@material-ui/core/styles';

export const ProfilePageStyle = makeStyles(theme => ({
  notification: {
    bottom: '10%',

  },
  root: {
    fontFamily: 'Roboto',
    height: '100vh',
    '& .footerDiv': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '90px',
      zIndex: '9999',
      padding: '12px 15px',
      maxWidth: '412px',
      textAlign: 'center',
      margin: '0px auto',
      background: '#F8F6F7',
      '& .buttons': {
        justifyContent: 'space-between',
      },
      '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '64px',
        height: '32px',
        margin: '4px auto',
      },
      '& .notifcation-icon': {
        position: 'relative',
      },
      '& .notifcation-icon .number': {
        background: '#F26F62',
        border: '1px solid #FFFFFF',
        width: '20px',
        height: '20px',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        position: 'absolute',
        top: '-12px',
        right: '-10px',
      },
      '& .footerButton': {
        textDecoration: 'none',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.5px',
        color: '#2C150F',
        '&.active .icon': {
          background: '#F26F62',
          borderRadius: '20px',
        },
        '&.active .icon svg * ': {
          fill: '#ffffff',
        },
      },
    },
    '& .subFooter': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '85px',
      padding: '12px 16px',
      maxWidth: '412px',
      textAlign: 'center',
      margin: '0px auto',
      display: 'flex',
      alignItems: 'center',
      '& .btnList': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        listStyleType: 'none',
        margin: '0',
        padding: '0',
        width: '100%',
      },
      '& .btnList.flex-end': {
        justifyContent: 'flex-end',
      },
      '& .btnList button ': {
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '28px',
        color: '#201A18',
        margin: '0',
        padding: '0',
        background: 'none',
        border: 'none',
      },
      '& .btnList .confrim': {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        background: '#F26F62',
        borderRadius: '100px',
        width: '100%',
        padding: '18px 10px',
      },

      '& .btnList img': {
        marginLeft: '10px',
      },
      '& .btnList .back': {
        margin: '0 10px 0 0',
      },
    },
  },

  cover: {
    width: '100%',
    overflow: 'auto',
    backgroundPosition: 'top center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
  },
  coverPadding: {
    padding: '20px 16px',
    height: 'calc(100vh - 155px)',
    position: 'relative',
    overflow: 'auto',
    '& .help-wrap': {
      position: 'fixed',
      bottom: '30px',
      left: '0',
      right: '0',
      maxWidth: '412px',
      width: '100%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      zIndex: '9',
      padding: ' 0 16px',
    },
    '& .help-btn': {
      backgroundColor: ' #FFEAE8',
      boxShadow: ' 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
      borderRadius: '16px',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#3B0900',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      padding: '18px 21px',
      cursor: 'pointer',
    },
    '& .help-btn img': {
      marginRight: '16px',
    },
  },

  /* --- Header --- */
  topHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '18px 16px ',
    borderRadius: ' 0px 0px 16px 16px',
  },
  topPart: {
    width: '100%',
  },
  topP1: {
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '0.5481481552124023px',
    textAlign: 'left',
    margin: '0',
    color: '#201A18',
    fontWeight: 500,

    '& img ': {
      marginRight: '13px',
    },
    '& a': {
      textDecoration: 'none',
      color: '#201A18',
    },
  },
  topP2: {
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'left',
    color: '#201A18',
    margin: '0',
    fontWeight: 500,
    '& img ': {
      marginRight: '13px',
    },
    '& a': {
      textDecoration: 'none',
      color: '#201A18',
    },
  },

  Profile: {
    height: ' 100%',
    overflow: 'auto',
    '& .profile-card': {
      background: ' #FFEAE8',
      boxShadow: '0px 0px 4px 2px #FDDAD7',
      borderRadius: '12px',
    },
    '& .profile-wrap': {
      display: 'flex',
      alignItems: 'center',
      padding: '14px 20px 16px',
      borderBottom: ' 1px solid #FFBAB5',
    },
    '& .profile-wrap .icon': {
      display: 'flex',
      alignItems: 'center',
      width: '70px',
      height: '70px',
      backgroundColor: '#F26F62',
      borderRadius: '100%',
      justifyContent: 'center',
      marginRight: '20px',
    },
    '& .profile-wrap .info h2': {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '22px',
      color: '#201A18',
      margin: '0 0 6px',
      letterSpacing: ' 0.1px',
      display: 'flex',
      alignItems: 'center',

    },
    '& .profile-wrap .info h2 span': {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '22px',
      color: '#201A18',
      letterSpacing: ' 0.1px',
      background: ' #FFBAB4',
      borderRadius: '4px',
      padding: '2px 10px',
      marginLeft: '10px',
    },
    '& .profile-wrap .info a': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: ' 0.25px',
      color: '#666666',
      textDecoration: 'none',
    },
    '& .profile-row': {
      display: 'flex',
      alignItems: 'center',
      padding: '15px 20px',
    },
    '& .profile-row .col': {
      width: '50%',
      position: 'relative',
    },
    '& .profile-row .col:before': {
      position: 'absolute',
      content: ' "" ',
      background: '#FFBAB5',
      width: '1px',
      height: '20px',
      right: '0',
      top: '0',
      bottom: '0',
      margin: 'auto',
    },
    '& .profile-row .col:last-child': {
      paddingLeft: '20px',
    },
    '& .profile-row .col:last-child:before': {
      display: 'none',
    },
    '& .profile-row .title': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#201A18',
      margin: '0 0 3px',
      letterSpacing: ' 0.1px',
      display: 'block',
    },
    '& .profile-row .desc': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#201A18',
      letterSpacing: ' 0.1px',
    },
    '& .profile-setting': {
      marginTop: '20px',
    },
    '& .profile-setting .setting-list': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '15px 20px',
      backgroundColor: '#FFFFFF',
      borderRadius: '12px',
      margin: '0 0 10px',
      cursor: 'pointer',
    },
    '& .profile-setting .setting-list:last-child': {
      margin: '0',
    },
    '& .profile-setting .setting-list .icon': {
      display: 'flex',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      backgroundColor: '#F8F6F7',
      borderRadius: '10px',
      justifyContent: 'center',
    },
    '& .profile-setting .setting-list .setting-wrap': {
      width: 'calc(100% - 40px)',
      paddingLeft: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .profile-setting .setting-list .setting-wrap h2': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#201A18',
      letterSpacing: ' 0.1px',
      margin: '0',
      maxWidth: '112px',
    },
    '& .profile-setting .setting-list .setting-wrap .log-btn': {
      color: '#F26F62',
    },
    '& .profile-setting .setting-list .setting-wrap .p-list': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .profile-setting .setting-list .setting-wrap .p-list .icon': {
      border: '1px solid #FFFFFF',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '14px',
      color: ' #FFFFFF',
    },
    '& .profile-setting .setting-list .setting-wrap .p-list .right': {
      marginLeft: '10px',
      transform: 'rotate(90deg)',
    },
    '& .profile-setting .setting-list .setting-wrap .p-list .icon.blue': {
      background: 'linear-gradient(0deg, #2E458B, #2E458B), url(photo-1506794778202-cad84cf45f1d.jpg)',
      zIndex: '2',
    },
    '& .profile-setting .setting-list .setting-wrap .p-list .icon.purple': {
      background: 'linear-gradient(0deg, #C29DDC, #C29DDC), url(95.jpg)',
      marginLeft: ' -16px',
      zIndex: '1',
    },
    '& .profile-setting .setting-list .setting-wrap .p-list .icon.green': {
      background: 'linear-gradient(0deg, #ABF0C9, #ABF0C9), url(44.jpg)',
      marginLeft: ' -16px',
    },
    '& .profile-setting .setting-list .setting-wrap .p-list .text': {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      color: ' #F26F62',
      marginLeft: ' 5px',
    },
  },

  reportWrap: {
    '& .report-title': {
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0.1px',
      color: '#201A18',
      margin: '0',
    },
    '& .report-note': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#666666',
      margin: '10px 0 20px',
    },
    '& .report-notification': {
      background: ' #FFEAE8',
      borderRadius: '12px',
      padding: '11px 20px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '50px',
    },
    '& .report-notification .icon': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '20px',
      width: '100%',
      height: '20px',
      backgroundColor: '#FFFFFF',
      borderRadius: '100%',
      justifyContent: 'center',
      backdropFilter: 'blur(27.1828px)',
      marginRight: '10px',
    },
    '& .reportList': {
      background: '#FFFFFF',
      border: '1px solid #FBDFDD',
      boxShadow: ' 0px 0px 4px 2px #FAE4E2',
      borderRadius: '12px',
      padding: '40px 20px 30px',
      '& .question': {
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '0.1px',
        color: '#201A18',
        margin: '0 0 40px',
      },
      '& .option': {
        padding: '0',
        margin: '0',
        listStyleType: 'none',
      },
      '& .option li': {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: '#201A18',
        backgroundColor: '#F2F1F7',
        boxShadow: '0px 0px 4px 1px #E5E5E6',
        borderRadius: '12px',
        padding: '13px 20px',
        marginBottom: '20px',
      },
      '& .option li:last-child': {
        marginBottom: '0',
      },
      '& .option li.openActive': {
        backgroundColor: '#F26F62',
        color: '#ffffff',
      },
      '& .option li.completedActive': {
        backgroundColor: '#AFAEBB',
        color: '#ffffff',
      },
    },

  },
  myClinical: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '12px',
    marginBottom: '10px',
    '& .setting-list.show .setting-wrap .p-list .right': {
      transform: 'rotate(270deg)',
    },

    '& .setting-list': {
      margin: '0 !important',
      borderRadius: '0 !important',
      backgroundColor: 'transparent !important',
    },
    '& .myClinicalWrap  ': {
      display: 'none',
      borderTop: '1px solid #DAE5E3',
      padding: '15px 20px',
    },
    '& .myClinicalWrap.show  ': {
      display: 'block',
    },

  },

  clinicalList: {
    marginBottom: '15px',
    paddingBottom: '15px',
    borderBottom: '1px solid #DAE5E3',
    '&:last-child': {
      marginBottom: '0',
      paddingBottom: '0',
      borderBottom: 'none',
    },
    '& .clinicalWrap': {
      display: 'flex',
      alignItems: 'center',
      // background: '#FFFFFF',
      // borderRadius: '12px',
      // padding: '15px 20px',
    },
    '& .clinicalWrap .icon': {
      border: '1px solid #FFFFFF',
      borderRadius: '100px',
      width: '45px',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '600',
      fontSize: '14px',
      color: '#FFFFFF',
    },
    '& .clinicalWrap .icon.blue': {
      background: 'linear-gradient(0deg, #2E458B, #2E458B), url(photo-1506794778202-cad84cf45f1d.jpg)',
    },
    '& .clinicalWrap .icon.purple': {
      background: 'linear-gradient(0deg, #C29DDC, #C29DDC), url(photo-1506794778202-cad84cf45f1d.jpg)',
    },
    '& .clinicalWrap .icon.green': {
      background: 'linear-gradient(0deg, #ABF0C9, #ABF0C9), url(photo-1506794778202-cad84cf45f1d.jpg)',
    },
    '& .clinicalWrap .icon.yellow': {
      background: 'linear-gradient(0deg, #FFBE5B, #FFBE5B), url(photo-1506794778202-cad84cf45f1d.jpg)',
    },
    '& .clinicalWrap .icon.skyblue': {
      background: 'linear-gradient(0deg, #75CCDF, #75CCDF), url(photo-1506794778202-cad84cf45f1d.jpg)',
    },
    '& .clinicalWrap .clinicaldesc': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // width: 'calc(100% - 45px)',
      // paddingLeft: '20px',
      width: '100%',
      flexWrap: 'wrap',
    },
    '& .clinicalWrap .clinicaldesc .title': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: ' 0.1px',
      color: ' #201A18',
      margin: '0',
    },
    '& .clinicalWrap .clinicaldesc .desc': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: ' 0.1px',
      color: ' #666666',
      margin: '2px 0',
      width: '100%',
    },
    '& .clinicalWrap .clinicaldesc .desc img': {
      marginRight: '10px',
    },
    '& .clinicalWrap .clinicaldesc .number': {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: ' 0.1px',
      color: ' #666666',
      margin: '0',
    },
    '& .clinicalWrap .clinicaldesc .number img': {
      marginRight: '10px',
    },
  },


  modal: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0 auto',
    maxWidth: '412px',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    textAlign: 'center',
    padding: ' 0 50px',
    display: 'none',


    '& .overlay': {
      position: 'absolute',
      content: ' "" ',
      backgroundColor: 'rgba(0,0,0,.6)',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: -1,
    },
    '& .modalContent': {
      background: 'linear-gradient(0deg, #F2F1F7, #F2F1F7), #FFFBFF',
      borderRadius: '28px',
      padding: '27px 24px 34px',
    },
  },
  modalActive: {
    display: 'flex',
  },

  helpModal: {
    '& h2': {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#201A18',
      margin: '22px 0 16px',
      textAlign: 'left',
    },
    '& p': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#201A18',
      textAlign: 'left',
      margin: '0 0 38px',
    },
    '& p span': {
      fontSize: '16px',
      display: 'block',
      margin: '0 0 15px',
    },
    '& .close-btn': {
      textAlign: 'right',
    },
    '& .close-btn button': {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#A83917',
      background: 'none',
      border: 'none',
      padding: '0',
    },

  },
  reportModal: {
    '& p': {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#201A18',
      textAlign: 'left',
      margin: '20px 0 38px',
    },
    '& .close-btn': {
      textAlign: 'right',
    },
    '& .close-btn button': {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#A83917',
      background: 'none',
      border: 'none',
      padding: '0',
    },

  },


}));
