import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { MedicationPageStyle } from '../../styles';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { isLabelMedActive } from 'entities/LabeledMedication';
import { CheckBox } from 'app/components/CheckBox';
import { renderMedicationTakeFreqString } from 'services/helpers/medication';
import CheckIcon from '../../../Assets/checkicon.svg';
import Awaiting from '../../../Assets/awaiting.png';
import moment from 'moment-timezone';
import { formatShortDate } from 'services/helpers/date';
import { POSTPatientnextTap } from 'services/api/tap';

interface Props {
  dailyTapsTotal: number;
  dailyTapsRemain: number;
  labeledMedications?: any[];
  lmDailyTaps?: { needed: number | undefined; labelMedId: number; tapped: number }[];
  nextTap?;
}

const dailyTitle = (
  dailyTapsRemain: number,
  lmAwaitingTaps: number,
  lmActiveTaps: number,
  dailyTapsTotal: number,
) => {
  if (lmActiveTaps === 0 && lmAwaitingTaps === 0) {
    return 'Your account is active.'
  }
  if (lmActiveTaps === 0 && lmAwaitingTaps > 0) {
    return 'Activate your Tappt label'
  }
  if (lmActiveTaps > 0) {
    if (dailyTapsTotal > 0) {
      if (dailyTapsRemain > 0) {
        return 'Remaining taps for today'
      } else {
        return 'You have completed all your taps for today.'
      }
    } else {
      return 'You have no taps scheduled for today.'
    }
  }
  return 'Your account is active.'
};

const dailyTapRemainStr = (
  dailyTapsRemain: number,
  lmAwaitingTaps: number,
  lmActiveTaps: number,
  dailyTapsTotal: number,
  nextTap?,
) => {
  if (lmActiveTaps === 0 && lmAwaitingTaps === 0) {
    return 'You have no medications assigned to your account.'
  }
  if (lmActiveTaps === 0 && lmAwaitingTaps > 0) {
    return `Tap your smartphone against the Tappt label with your next medication dose.
    Go to your Resource tab to learn how.`
  }
  if (dailyTapsRemain > 0) {
    if (dailyTapsRemain < 0) return 'You Have Exceeded your Taps for Today';
    return `You've completed ${dailyTapsTotal - dailyTapsRemain} out of ${dailyTapsTotal} taps for today`;
  }
  if (dailyTapsTotal === 0) {
    if (nextTap?.date) {
      return `Your next tap is scheduled for  ` +
        formatShortDate(nextTap?.date)
    }
  }
  if (dailyTapsRemain === 0) {
    if (nextTap?.date) {
      if (moment(nextTap?.date).isSame(moment(), 'day'))
        return (
          `Your next tap is scheduled for  ` +
          formatShortDate(moment(nextTap?.date).add(1, 'day').toDate())
        );
      return (
        `Your next tap is scheduled for  ` +
        formatShortDate(nextTap?.date)
      );
    }
  }
  return 'You have no taps scheduled for today.';
};

const DailyTaps = ({
  dailyTapsRemain,
  labeledMedications,
  dailyTapsTotal,
  lmDailyTaps,
}: Props) => {

  const classes = MedicationPageStyle();

  const [progressValue, setProgressValue] = useState(0);
  const [activeArray, setActiveArray] = useState<any>()
  const [awatingArray, setAwatingArray] = useState<any>();
  const [nextTap, setNextTap] = useState<{ lmId: number; date: Date }>();

  useEffect(() => {
    setProgressValue(() => {
      if (!dailyTapsTotal) return 0;
      const progressInPercent = (100 * dailyTapsRemain) / dailyTapsTotal;
      if (progressInPercent > 100) return 100;
      if (progressInPercent < 0) return 0;
      return progressInPercent;
    });
    if (dailyTapsRemain === 0 || dailyTapsTotal === 0) {
      POSTPatientnextTap().then(tap => setNextTap(tap));
    }
  }, [dailyTapsRemain, dailyTapsTotal]);

  useEffect(() => {
    setActiveArray(labeledMedications?.filter(lm => isLabelMedActive(lm)))
    setAwatingArray(labeledMedications?.filter(lm => !isLabelMedActive(lm)))
  }, [labeledMedications])

  const checkFunc = (needed, tapped) => {
    let arr: any = []
    for (let index = 0; index < needed; index++) {
      arr.push(index)
    }
    const arrayCheck = arr?.map((i) => {
      return (
        <CheckBox key={i} id={i} label="" isSelected={i < tapped ? true : false} />
      )
    })
    return arrayCheck
  }

  return (
    <>
      <Typography component="div" className={classes.dailyTaps}>
        <h2 className="dailyHeading">Daily taps</h2>
        <div className='dailytapHedaer'>
          <div className='dailytapDesc'>
            <h2 className='title'>{dailyTitle(
              dailyTapsRemain,
              awatingArray?.length ??
              0,
              activeArray?.length ??
              0,
              dailyTapsTotal,
            )}</h2>
            <p className='desc'>{dailyTapRemainStr(
              dailyTapsRemain,
              awatingArray?.length ??
              0,
              activeArray?.length ??
              0,
              dailyTapsTotal,
              nextTap
            )}</p>
          </div>
          {
            activeArray?.length === 0 && awatingArray?.length > 0 ?
              <div className='tapsCheck'>
                <img src={Awaiting} style={{ width: '90px', borderRadius: '100%' }} />
              </div>
              :
              <div className='circular'>
                {
                  dailyTapsRemain === 0 ?
                    (<div className='tapsCheck'>
                      <img src={CheckIcon} style={{ width: '70px', }} />
                    </div>)
                    :
                    <CircularProgressbar
                      value={100 - progressValue}
                      text={`${dailyTapsRemain < 0 ? '' : dailyTapsRemain}`}
                      background
                      backgroundPadding={4}
                      strokeWidth={6}
                      styles={buildStyles({
                        backgroundColor: '#F26F62',
                        textColor: '#ffffff',
                        pathColor: '#ffffff',
                        trailColor: 'transparent',
                        textSize: '45px'
                      })}
                    />
                }
              </div>
          }
        </div>
        {
          activeArray?.map((lm, i) => {
            const { needed = 0, tapped = 0 } = {
              ...lmDailyTaps?.find(({ labelMedId }) => labelMedId === lm.id),
            };
            return needed ?
              <div key={i} className='dailyTapsList'>
                <div className='listDesc'>
                  <h2 className='title'>{lm.medication.brand}</h2>
                  <p className='desc'>{renderMedicationTakeFreqString(
                    lm.amount,
                    lm.time_period,
                    lm.quantity,
                  )}</p>
                  <div className='moreDetail'>
                    <div className='active-btn'>Active</div>
                  </div>
                </div>
                <div className='checkboxList'>
                  {checkFunc(needed, tapped)}
                </div>
              </div>
              :
              null
          })
        }
        {awatingArray?.map((lm, i) => {
          return (
            <div key={i} className='dailyTapsList'>
              <div className='listDesc'>
                <h2 className='title'>{lm.medication.brand}</h2>
                <p className='desc'>{renderMedicationTakeFreqString(
                  lm.amount,
                  lm.time_period,
                  lm.quantity,
                )}</p>
                <div className='moreDetail'>
                  <div className='unactive-btn'>Awaiting First Tap</div>
                </div>
              </div>
            </div>
          )
        })}
      </Typography>
    </>
  );
};

export default DailyTaps;
