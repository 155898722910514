import { makeStyles } from '@material-ui/core/styles';
import Path from '../../Auth/Assets/bg.png';

export const SurveyPageStyle = makeStyles(theme => ({
  root: {
    height: '100vh',
    background: '#fff',
    position: 'relative',
    textAlign: 'center',
  },
  login: {
    minHeight: '100vh',
    '& .topWrap': {
      height: '220px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '50px 0 0',
      textAlign: 'center',
    },
    '& .topWrap h2': {
      margin: '0 0 20px',
      width: '100%',
      fontWeight: '500',
      fontSize: '24px',
      color: '#F26F62',
    },
    '& .topWrap h2 p': {
      margin: '15px 0 0',
      width: '100%',
      fontWeight: '400',
      fontSize: '16px',
      color: '#201A18',
    },
    '& .topWrap .icon': {
      width: '150px',
      height: '150px',
      display: ' inline-block',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& .bottomWrap': {
      minHeight: 'calc(100vh - 220px)',
      backgroundImage: `url('.${Path}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: ' top center',
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .bottomWrap .loginDetail': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      padding: ' 300px 16px',
    },
    '& .bottomWrap .loginBtn': {
      width: '100%',
    },
    '& .bottomWrap .loginBtn .btn-login': {
      background: '#F26F62',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: ' 0.1px',
      color: ' #FFFFFF',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .copyRight': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '27px',
      color: '#676767',
      width: '100%',
    },

    '& .help-wrap': {
      position: 'fixed',
      bottom: '30px',
      left: '0',
      right: '0',
      maxWidth: '412px',
      width: '100%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      zIndex: '9',
      padding: ' 0 16px',
    },
    '& .help-btn': {
      backgroundColor: ' #FFEAE8',
      boxShadow: ' 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
      borderRadius: '16px',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#3B0900',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      padding: '18px 21px',
      cursor: 'pointer',
    },
    '& .help-btn img': {
      marginRight: '16px',
    },

  },
  modal: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0 auto',
    maxWidth: '412px',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    textAlign: 'center',
    padding: ' 0 50px',
    display: 'none',


    '& .overlay': {
      position: 'absolute',
      content: ' "" ',
      backgroundColor: 'rgba(0,0,0,.6)',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: -1,
    },
    '& .modalContent': {
      background: 'linear-gradient(0deg, #F2F1F7, #F2F1F7), #FFFBFF',
      borderRadius: '28px',
      padding: '27px 24px 34px',
    },
  },
  modalActive: {
    display: 'flex',
  },

  helpModal: {
    '& h2': {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#201A18',
      margin: '22px 0 16px',
      textAlign: 'left',
    },
    '& p': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#201A18',
      textAlign: 'left',
      margin: '0 0 38px',
    },
    '& p span': {
      fontSize: '16px',
      display: 'block',
      margin: '0 0 15px',
    },
    '& .close-btn': {
      textAlign: 'right',
    },
    '& .close-btn button': {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#A83917',
      background: 'none',
      border: 'none',
      padding: '0',
    },

  },

  successBg: {
    position: 'absolute',
    width: '150%',
    'object-fit': 'crop',
    height: '906.7px',
    left: '-25%',
    top: '338px',
    overflow: 'hidden',
  },
  successBgContainer: {
    width: '100%',
    display: 'block',
    overflow: 'hidden',
  },
  successH1: {
    position: 'absolute',

    left: '10%',
    right: '10%',
    top: '8%',
    'font-family': 'Roboto',
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '46px',
    'line-height': '51px',
    'text-align': 'center',
    'letter-spacing': '0.696296px',
    color: '#F86060',
  },
  successH2: {
    position: 'absolute',
    left: '10%',
    right: '10%',
    top: '15%',

    'font-family': 'Roboto',
    'font-style': 'normal',
    'font-weight': '200',
    'font-size': '26px',
    'line-height': '32px',

    /* identical to box height, or 123% */
    'text-align': 'center',

    color: '#424248',
  },
  successText3: {
    position: 'absolute',
    bottom: '10%',
    left: '10%',
    right: '10%',
    color: 'darkgray',
    'font-size': '22px',
    'text-align': 'center',
  },

  innercircle: {
    '@media (max-height:450px)': {
      margin: '10px auto 10px',
    },
    margin: '230px auto 10px',
    display: 'block',
    height: '279px',
    width: '259px',
  },
}));
