import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Path from '../../Assets/bg.png';

export const useSmStylesOtp = makeStyles(theme => ({
  root: {
    height: '100vh',
    background: '#fff',
    position: 'relative',

  },
  login: {
    '& .topWrap': {
      height: '250px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      textAlign: 'center',
      padding: '0  16px',
    },
    '& .topWrap h2': {
      margin: '0 0 20px',
      width: '100%',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#201A18',
    },
    '& .topWrap .icon': {
      width: '160px',
      height: '160px',
      display: ' inline-block',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& .topWrap .back': {
      textAlign: 'left',
      width: '100%',
      padding: '25px 0 ',
    },
    '& .bottomWrap': {
      height: 'calc(100vh - 250px)',
      backgroundImage: `url('.${Path}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: ' top center',
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },


    '& .bottomWrap .loginDetail': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      padding: ' 190px 16px 40px',
    },
    '& .bottomWrap .loginBtn': {
      width: '100%',
      marginTop: '250px',
    },
    '& .bottomWrap .loginBtn .btn-login': {
      background: '#F26F62',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: ' 0.1px',
      color: ' #FFFFFF',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .copyRight': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '27px',
      color: '#676767',
      width: '100%',
    },
    '& .bottomWrap .forgot': {
      textAlign: 'center',
      marginTop: '40px',
    },
    '& .bottomWrap .forgot a': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#F26F62',
      letterSpacing: '0.25px',
      textDecoration: 'none',
    },
    '& .bottomWrap .btn-login': {
      background: 'rgba(31, 31, 31, 0.12)',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: ' 0.1px',
      color: '#191C1C',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .btn-active': {
      background: '#F26F62',
      borderRadius: '100px',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: ' 0.1px',
      color: '#fff',
      border: 'none',
      width: '100%',
      padding: '19px 10px',
    },
    '& .bottomWrap .btn-login.active': {
      background: '#F26F62',
      color: ' #FFFFFF',
    },
    '& .bottomWrap .hedaingError': {
      fontWeight: '400',
      fontSize: '12px',
      color: '#53433F',
      letterSpacing: '0.4px',
    },
    '& .bottomWrap .listError': {
      padding: '0',
      margin: '0',
      listStyleType: 'none',
    },
    '& .bottomWrap .listError li': {
      fontWeight: '400',
      fontSize: '12px',
      color: '#53433F',
      letterSpacing: '0.4px',
      marginBottom: '6px',
      position: 'relative',
      paddingLeft: '13px',
    },
    '& .bottomWrap .listError li.Error': {
      color: '#BA1A1A',
    },
    '& .bottomWrap .listError li.Error:before': {
      background: '#BA1A1A',
    },
    '& .bottomWrap .listError li:before': {
      position: 'absolute',
      content: ` "" `,
      background: '#A09FAA',
      width: '8px',
      height: '8px',
      borderRadius: '100%',
      left: '0',
      top: '5px',
    },
    '& .bottomWrap .listError ul': {
      padding: '5px 0 0 20px',
      margin: '0',
      listStyleType: 'none',
    },
    '& .bottomWrap .listError ul li': {
      fontSize: '10px',
      marginBottom: '3px',
    },
    '& .bottomWrap .listError ul li:before': {
      width: '6px',
      height: '6px',
    }

  },
  header: {
    display: 'inline-flex',
    marginTop: '24px',
    zIndex: 9,
    position: 'relative',
  },
  fillimage: {
    left: '-0.27%',
    right: ' 0%',
    top: '20.67%',
    bottom: ' 0%',
    background: 'rgba(255, 236, 197, 0.8)',
  },
  fillimages: {
    top: '0',
    left: '0%',
    right: '0',
    width: '100%',
    bottom: '0%',
    position: 'absolute',
    zIndex: 2,
    objectFit: 'cover',
  },
  newbackbtn: {
    width: '12px',
    height: '20px',
    marginTop: '11px',
  },
  banner: {
    flex: 1,
    padding: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    flexDirection: 'column',
  },
  paper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    minWidth: '350px',
    borderRadius: '3%',
    [theme.breakpoints.up('xs')]: {
      minWidth: '250px',
      flex: 3,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '250px',
      flex: 2,
    },
  },
  logoWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
  },

  bannerTextWrap: {
    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: '#fff',
  },
  TextMessage: {
    height: '36px',
    left: '36px',
    right: '36px',
    marginLeft: '15px',
    top: '121px',

    fontFamily: ' Mplus 1p',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    /* or 129% */

    letterSpacing: '0.3px',

    color: '#6B6B6F',
  },

  bannerText1: {
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    lineHeight: '41px',
    fontSize: '26px',
    marginLeft: '19px',
    letterSpacing: '0.548148px',
    color: '#4D4D5A',
  },
  bannerText2: {
    fontWeight: 500,
    lineHeight: '18px',
    fontSize: '14px',
    letterSpacing: '0.3px',
    color: '#6B6B6F',
    marginTop: '8px',
    zIndex: 9,
    position: 'relative',
  },
}));

export const useStylesOtp = makeStyles(theme => ({

  form: {
    width: '100%',
    '& .form-group': {
      marginBottom: '30px',

      '& .MuiFormControl-fullWidth': {
        margin: '0',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#6F7978',
        borderRadius: '4px',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        fontWeight: '400',
        fontSize: '16px',
        color: '#A83917',
        backgroundColor: '#fff',
      },
      '& .MuiInputLabel-formControl': {
        fontWeight: '400',
        fontSize: '16px',
        color: '#8E9190',
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#A83917',
      },
    },
    '& .phone-group': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& .MuiOutlinedInput-input': {
        padding: '18.5px 44px 18.5px 14px',
      },
      '& .icon': {
        position: 'absolute',
        right: '15px',
        top: '0',
        bottom: '0',
        margin: 'auto 0',
        width: '20px',
        height: '20px',
      },
      '& .react-tel-input': {
        width: '56px',
        marginRight: '10px',
      },
      '& .react-tel-input .flag-dropdown': {
        backgroundColor: '#fff',
        border: '1px solid #6F7978',
        width: '56px',
        height: '56px',
        position: 'static',
        borderRadius: '4px',
      },
      '& .react-tel-input .selected-flag': {
        margin: 'auto',
        padding: '10px 6px',
      },
      '& .react-tel-input .country-list': {
        zIndex: '9',
      },
    },
  },

  loginButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 1),
    },
  },
  forgotPassword: {
    color: '#1F8EFA',
    fontSize: '14px',
    textDecoration: 'underline',
  },
  newbutton: {
    maxWidth: '343px',
    width: '100%',
    marginTop: '44px',
    fontSize: '18px',
    fontWeight: 600,
    background: 'linear-gradient(140.62deg, #F97C74 -12%, #F86060 100%)',
  },
  resendbutton: {
    fontWeight: 500,
    marginTop: '21px',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.1485px',
    color: '#F86664',
  },
  otpFields: {
    display: 'grid',
    gridTemplateColumns: '50px 50px 50px 50px',
    gridGap: '15px',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '245px',
    height: '50px',
  },
  inputfield: {
    border: 'none',
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '26px',
    lineHeight: '41px',
    letterSpacing: '0.548148px',
    background: 'rgba(232, 230, 228, 0.7)',
    borderRadius: '5px',
  },

  newInput: {
    left: '16px',
    right: '16px',
    top: '128px',
    bottom: '634px',
    padding: '10px',
    width: '300px',
    background: '#FFFFFF',
    border: '1px solid #FC8263',
    boxShadow: '0px 9px 24px rgba(45, 45, 52, 0.15)',
    borderRadius: '10px',
  },
  grid: {
    backgroundColor: 'grey',
    // height: '50vh',
    textAlign: 'center',
    marginTop: '50px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
