import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import React from "react";

interface Props {
  progress: number;
  label?: string | number;
  needed: number;
  unExpected?: boolean | undefined;
}

const ProgressBar = ({ progress, label, needed, unExpected }: Props) => {
  return needed > 0 ? (
    <div className={unExpected ? "unExpected" : ""}>
      <CircularProgressbar
        value={progress}
        background
        strokeWidth={6}
        backgroundPadding={6}
        styles={
          progress < 100
            ? buildStyles({
                backgroundColor: "transparent",
                pathColor: "#F26F62",
                textColor: "#676767",
                textSize: "24px",
                trailColor: "#EEEEEE",
              })
            : buildStyles({
                backgroundColor: "#F26F62",
                pathColor: "#fff",
                textColor: "#FFFFFF",
                textSize: "24px",
                trailColor: "#fff",
              })
        }
        className={progress === 100 ? "activeProgress" : ""}
        text={label?.toString() ?? ""}
      />
    </div>
  ) : (
    <CircularProgressbar
      value={0}
      background
      strokeWidth={0}
      backgroundPadding={6}
      styles={buildStyles({
        backgroundColor: "transparent",
        pathColor: "#F26F62",
        textColor: "#676767",
        textSize: "24px",
        trailColor: "#EEEEEE",
      })}
      className={progress === 100 ? "activeProgress" : ""}
      text={label?.toString() ?? ""}
    />
  );
};

export default ProgressBar;
